import {
    PipelineConfigPartFragment,
    PipelinePartFragment,
    PipelineStagesConfigFragment,
    PipelineStageStatisticsPartFragment,
    PipelineStatisticsPartFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    createCurrencyValue,
    CurrencyValue,
} from 'src/domain/models/currency-value/currency-value.model';
import { MinimalDeal } from 'src/domain/models/deal/deal.model';
import {
    createStage,
    Stage,
} from 'src/domain/models/deal-stage/deal-stage.model';
import { sortByOrder } from 'src/utils/array.utils';

export interface StageStatistics {
    id: string;
    observedConversionRate: number;
    totalValueOfAtRiskDeals: number;
    totalValueOfHealthyDeals: number;
    inStageDeals: number;
    progressedDeals: number;
    markedDoneDeals: number;
    disqualifiedDeals: number;
    totalDeals: number;
    percentage: number;
    goal: number;
    targetConversionRate: number;
    inPlayDealsOfStage: MinimalDeal[];
    inPlayDealsCount: number;
}
interface PipelineStatistics {
    stages: StageStatistics[];
}
interface PipelineStageConfig {
    stageMap: Map<string, Stage>;
    stageIdsInOrder: string[];
    lastStageId: string;
}
interface PipelineConfig {
    averageDealValue: CurrencyValue;
    revenueGoal: CurrencyValue;
    timePeriodStart: Date;
    stageConfig: PipelineStageConfig;
    goalsEnabled: boolean;
}
export interface Pipeline {
    id: string;
    name: string;
    statistics: PipelineStatistics;
    config: PipelineConfig;
}

export const createStageStatistics = (
    rawStageStatistics: PipelineStageStatisticsPartFragment,
    inPlayDealsOfStage: MinimalDeal[] = [],
    inPlayDealsCount = 0,
): StageStatistics => {
    const {
        id,
        observedConversionRate,
        atRiskDeals,
        healthyDeals,
        inStageDeals,
        progressedDeals,
        disqualifiedDeals,
        markedDoneDeals,
        goal,
        targetConversionRate,
    } = rawStageStatistics;
    const totalDeals =
        inStageDeals.numberOfDeals +
        progressedDeals.numberOfDeals +
        disqualifiedDeals.numberOfDeals +
        markedDoneDeals.numberOfDeals;
    return {
        id,
        observedConversionRate,
        totalValueOfAtRiskDeals: parseInt(atRiskDeals.totalDealValue),
        totalValueOfHealthyDeals: parseInt(healthyDeals.totalDealValue),
        inStageDeals: inStageDeals.numberOfDeals,
        progressedDeals: progressedDeals.numberOfDeals,
        disqualifiedDeals: disqualifiedDeals.numberOfDeals,
        markedDoneDeals: markedDoneDeals.numberOfDeals,
        totalDeals,
        percentage: Math.floor((totalDeals / goal) * 100),
        goal,
        targetConversionRate,
        inPlayDealsOfStage,
        inPlayDealsCount,
    };
};

const createPipelineStatistics = (
    rawPipelineStatistics: PipelineStatisticsPartFragment,
): PipelineStatistics => {
    const { stages } = rawPipelineStatistics;
    return { stages: stages.map((stage) => createStageStatistics(stage)) };
};

const createPipelineStageConfig = (
    rawPipelineStageConfig: PipelineStagesConfigFragment,
): PipelineStageConfig => {
    const {
        initialStage: initialStageId,
        lastStage: lastStageId,
        stages: rawStages,
    } = rawPipelineStageConfig;

    const stageIdsInOrder = sortByOrder(rawStages).map(
        (rawStage) => rawStage.id,
    );

    const stageMap: Map<string, Stage> = new Map();
    rawStages.forEach((rawStage) => {
        const stage = createStage(rawStage, lastStageId, initialStageId);
        stageMap.set(stage.id, stage);
    });

    return {
        stageIdsInOrder,
        stageMap,
        lastStageId,
    };
};

const createPipelineConfig = (
    rawPipelineConfig: PipelineConfigPartFragment,
): PipelineConfig => {
    const {
        averageDealValue,
        revenueGoal,
        timePeriodStart,
        stages: rawStageConfig,
        goalsEnabled,
    } = rawPipelineConfig;
    return {
        averageDealValue: createCurrencyValue(averageDealValue),
        revenueGoal: createCurrencyValue(revenueGoal),
        timePeriodStart: new Date(timePeriodStart),
        stageConfig: createPipelineStageConfig(rawStageConfig),
        goalsEnabled,
    };
};

export const createPipeline = (rawPipeline: PipelinePartFragment): Pipeline => {
    const { id, name, statistics, config } = rawPipeline;
    return {
        id,
        name,
        statistics: createPipelineStatistics(statistics),
        config: createPipelineConfig(config),
    };
};
