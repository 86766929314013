import { makeAutoObservable } from 'mobx';

import { AchievementService } from 'src/app-features/achievements/domain/service/achievements.service';
import { XRayApi } from 'src/data/api/actions/xray/xray.api';
import { UserAchievementName } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { LeadsStore } from 'src/data/stores/leads/leads.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    ActionFeatureLaunchSource,
    ActionFeaturesIdentifiers,
} from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { Lead } from 'src/domain/models/lead/lead.model';
import i18n from 'src/resources/translations/i18next';
import {
    abortOngoingRequests,
    handleRequest,
    isAbortError,
} from 'src/utils/handle-request.utils';

import { ARTICLE_WIZARD_REQUEST_KEY, RECENT_ARTICLES_KEY } from './utils';
import { IXRayFeature } from './xray-interface.feature';

export interface ArticleWizardBasePayload {
    projectId?: string;
    documentId?: string;
    dealId?: string;
}

export interface AskWizardPayload extends ArticleWizardBasePayload {
    question: string;
}

export interface WizardFlyoutDataContext {
    /**
     * The lead id (or projectId). Can be a standalone lead (wizard on screening page) or a lead linked to a deal.
     * When the deal is using the new neural search structure, this is not used, instead we simply
     * use the neural search article ID + deal ID to run the wizard.
     */
    projectId?: string;
    /** When using the old structure with Lead (Project), the lead may have one or more documents. In some cases, we want to reference
     * a specific document, for example, when the user selects an article (document) from the select.
     */
    documentId?: string;
    /**
     * For tracking purposes
     * */
    launchSource?: ActionFeatureLaunchSource;
    /**
     * The deal id, used when the wizard is launched from the deal view.
     */
    dealId?: string;
}

export class XRayFeature implements IXRayFeature {
    isFlyoutOpened = false;
    flyoutDataContext: WizardFlyoutDataContext | undefined = undefined;
    xRayActionAnswer: string[] | string | null = null;
    isXRayAnswerLoading = false;
    customQuestion = '';
    selectedDocumentId: string | undefined = undefined;
    isArticleWizardError = false;

    constructor(
        private mixpanelService: MixpanelService,
        private leadsStore: LeadsStore,
        private xRayApi: XRayApi,
        private baseStore: IBaseStore,
        private accountConfigurationStore: IAccountConfigurationStore,
        private achievementService: AchievementService,
    ) {
        makeAutoObservable(this);
    }

    get selectedLead(): Lead | undefined {
        const projectId = this.flyoutDataContext?.projectId;
        return projectId ? this.leadsStore.leads.get(projectId) : undefined;
    }

    get predefinedQuestions() {
        return (
            (this.accountConfigurationStore.actionFeaturesConfigurations?.find(
                (actionFeature) =>
                    actionFeature.name === ActionFeaturesIdentifiers.xray,
            )?.settings.questions ?? []) as string[]
        ).filter((q) => !!q);
    }

    setSelectedDocumentId = (documentId: string | undefined) => {
        this.selectedDocumentId = documentId;
    };

    setCustomQuestion = (question: string) => {
        this.customQuestion = question;
    };

    setXRayActionAnswer = (answer: string[] | string | null) => {
        this.xRayActionAnswer = answer;
    };

    setLoadingAnswer = (isLoading: boolean) => {
        this.isXRayAnswerLoading = isLoading;
    };

    isValidAnswer = (answer: string | null): answer is string =>
        !!answer && answer.length > 1;

    onSuccessAnswerNew = (
        question: string,
        answer: string | null,
        projectId?: string,
        documentId?: string,
    ) => {
        this.setXRayActionAnswer(
            this.isValidAnswer(answer) ? answer.split('\n') : '',
        );
        this.mixpanelService.trackEvent(MixpanelEventName.XRayRequestAnswer, {
            question,
            answer: this.isValidAnswer(answer) ? answer : 'No results',
            projectId,
            documentId: documentId ?? null,
        });

        if (!this.predefinedQuestions.includes(question.trim())) {
            this.achievementService.call(UserAchievementName.AskArticleWizard);
        }
    };

    askQuestionNew = (payload: AskWizardPayload) => {
        const { question, projectId, documentId, dealId } = payload;
        this.setXRayActionAnswer(null);

        const onNextValue = (value: string) => {
            if (this.isValidAnswer(value)) {
                this.setXRayActionAnswer(value.split('\n'));
            }
        };

        handleRequest(
            this.xRayApi.getAnswerHybrid,
            {
                projectId,
                question,
                documentId,
                dealId,
                onNextValue,
            },
            (answer) =>
                this.onSuccessAnswerNew(
                    question,
                    answer,
                    projectId,
                    documentId,
                ),
            this.setLoadingAnswer,
            (error) => {
                this.baseStore.onRequestFailed('x-ray-ask-question', error);
                if (error && !isAbortError(error)) {
                    this.isArticleWizardError = true;
                }
            },
            ARTICLE_WIZARD_REQUEST_KEY,
        );
    };

    trackXRayFeedback = (
        wasHelpful: boolean,
        question: string,
        answer: string,
        dealId?: string,
        pipelineId?: string,
        currentStageId?: string,
        leadId?: string,
        documentId?: string,
    ) => {
        this.mixpanelService.trackEvent(
            MixpanelEventName.XRayResponseFeedback,
            {
                wasHelpful,
                question,
                answer,
                dealId: dealId ?? null,
                pipelineId: pipelineId ?? null,
                currentStageId: currentStageId ?? null,
                leadId: leadId ?? null,
                documentId: documentId ?? null,
            },
            dealId,
        );
    };

    abortOngoingRequests = () => {
        abortOngoingRequests(ARTICLE_WIZARD_REQUEST_KEY);
    };

    summarizeArticle = (
        payload: ArticleWizardBasePayload,
        launchSource?: ActionFeatureLaunchSource,
    ) => {
        const { projectId, documentId, dealId } = payload;

        this.openArticleWizardFLyout({
            projectId,
            documentId,
            launchSource,
        });

        this.setCustomQuestion(i18n.t('actions.xray_helper.summarize_prompt'));

        this.askQuestionNew({
            question: this.customQuestion,
            projectId,
            documentId,
            dealId,
        });
    };

    runFirstQuestionForLead = (
        payload: ArticleWizardBasePayload,
        launchSource?: ActionFeatureLaunchSource,
    ) => {
        const { projectId, dealId, documentId } = payload;

        this.openArticleWizardFLyout({
            projectId,
            dealId,
            launchSource,
        });

        if (!this.predefinedQuestions.length) {
            return;
        }

        const firstQuestion = this.predefinedQuestions[0];
        this.askQuestionNew({
            question: firstQuestion,
            projectId,
            documentId,
            dealId,
        });
    };

    openArticleWizardFLyout = (dataContext: WizardFlyoutDataContext) => {
        this.isFlyoutOpened = true;
        this.flyoutDataContext = dataContext;

        this.mixpanelService.trackEvent(
            MixpanelEventName.LaunchedQuickAction,
            {
                quickActionName: ActionFeaturesIdentifiers.xray.toString(),
                launchSource: dataContext.launchSource ?? null,
                projectId: dataContext.projectId,
                documentId: dataContext.documentId ?? null,
            },
            dataContext.dealId,
        );

        this.setSelectedDocumentId(
            dataContext.documentId ?? RECENT_ARTICLES_KEY,
        );
        this.setCustomQuestion('');
        this.abortOngoingRequests();
        this.setLoadingAnswer(false);
        this.setXRayActionAnswer(null);
        this.isArticleWizardError = false;
    };

    closeArticleWizardFLyout = () => {
        this.isFlyoutOpened = false;
        this.flyoutDataContext = undefined;
        this.abortOngoingRequests();
    };
}
