import { makeAutoObservable } from 'mobx';
import {
    DiscoveredContactProfile,
    ContactDiscoveryResults,
    SearchMetadata,
} from './discovered-contact-profile.model';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { IContactFinderApi } from '../data/contact-finder.api';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { IDealContactsStore } from 'src/app-features/contact/data/stores/deal-contacts.store.interface';
import { CustomInput } from 'src/app-features/contact/data/model/deal-contacts-form.model';
import { PredefinedCustomFields } from 'src/app-features/contact/data/model/deal-contacts.model';
import { buildLinkedinLink } from 'src/utils/url.utils';
import { buildUniqueUserWebSessionId } from 'src/utils/web-session.utils';
import { UserStore } from 'src/data/stores/user/user.store';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';

export interface FilterPayload {
    companyName: string;
    keyword?: string;
    location?: string;
    title?: string;
}

export interface IContactFinderFeature {
    flyoutIsOpen: boolean;
    companyId: string | null;
    filters: FilterPayload;
    isLoading: boolean;
    currentPage: number;
    results: ContactDiscoveryResults;
    addedProfiles: string[];
    searchSessionId: string | null;
    setFlyoutIsOpen: (flag: boolean) => void;
    setFilter: (filterName: keyof FilterPayload, value?: string) => void;
    openFlyoutForCompany: (companyId: string, dealId: string) => Promise<void>;
    findAndSetContactsByFilter: (
        payload: FilterPayload,
        page: number,
        dealId: string,
    ) => Promise<void>;
    extractLinkedinProfileContactInfoAsCustomInputs: (
        contact: DiscoveredContactProfile,
    ) => Promise<CustomInput>;
    addProfileToProfiles: (profile: string) => void;
    trackContactCreated: (
        dealId: string,
        customInput: CustomInput,
        contactIndex: number,
    ) => void;
}

const defaultFilters: FilterPayload = {
    companyName: '',
};

const defaultMetadataState: SearchMetadata = {
    page: 0,
    pageSize: 25,
    total: 0,
    usedCompanyName: '',
};

export const createContactFinderFeature = (
    contactFinderApi: IContactFinderApi,
    dealContactsStore: IDealContactsStore,
    userStore: UserStore,
    mixpanelService: MixpanelService,
    baseStore: IBaseStore,
): IContactFinderFeature => {
    const feature = {
        flyoutIsOpen: false,
        filters: { ...defaultFilters },
        companyId: null as string | null,
        isLoading: false,
        currentPage: 0,
        results: {
            contacts: [],
            metadata: { ...defaultMetadataState },
        } as ContactDiscoveryResults,
        addedProfiles: [] as string[],
        searchSessionId: null as string | null,

        findAndSetContactsByFilter: async (
            payload: FilterPayload,
            page: number,
            dealId: string,
        ): Promise<void> => {
            feature.currentPage = page;

            const { companyName, keyword, location, title } = payload;

            try {
                const results = await handleRequestAsync(
                    contactFinderApi.findContactsForCompany,
                    { companyName, keyword, location, title, page },
                    (loading) => (feature.isLoading = loading),
                );

                if (results) {
                    feature.results = results;
                    feature.currentPage = results.metadata.page;

                    feature.trackSearchWithContactFinder(dealId);
                }
            } catch (error) {
                baseStore.onRequestFailed(
                    'find-contacts-by-company-name',
                    error as Error,
                );
            }
        },

        extractLinkedinProfileContactInfoAsCustomInputs: async (
            contact: DiscoveredContactProfile,
        ): Promise<CustomInput> => {
            try {
                const { linkedinUsername, hasEmail, hasPhone } = contact;

                /** Always add linkedin as custom input, since we already have this */
                const customInput: CustomInput = {
                    customValues: [
                        {
                            name: PredefinedCustomFields.linkedin,
                            value: buildLinkedinLink(linkedinUsername),
                        },
                    ],
                };

                /** Fetch email and/or phone if available */
                if (hasEmail || hasPhone) {
                    const contactInfo = await handleRequestAsync(
                        contactFinderApi.getLinkedinProfileContactInfo,
                        { linkedinUserName: linkedinUsername },
                    );

                    if (contactInfo) {
                        const emailList = contactInfo.workEmail;
                        const phoneList = contactInfo.phone;

                        if (emailList.length) {
                            customInput.customValues.push({
                                name: PredefinedCustomFields.mail,
                                value: emailList[0],
                            });
                        }

                        if (phoneList.length) {
                            customInput.customValues.push({
                                name: PredefinedCustomFields.telephone,
                                value: phoneList[0],
                            });
                        }
                    }
                }

                return customInput;
            } catch (error) {
                baseStore.onRequestFailed(
                    'get-linkedin-profile-contact-info',
                    error as Error,
                );
                throw error;
            }
        },

        setFlyoutIsOpen: (flag: boolean): void => {
            feature.flyoutIsOpen = flag;

            if (!flag) {
                //clearing the session
                feature.searchSessionId = null;
            }
        },

        setFilter: (filterName: keyof FilterPayload, value?: string) => {
            if (feature.filters) {
                feature.filters = {
                    ...feature.filters,
                    [filterName]: value,
                };
            }
        },

        openFlyoutForCompany: async (
            companyId: string,
            dealId: string,
        ): Promise<void> => {
            const company = dealContactsStore.companiesMap.get(companyId);
            if (!company) return;

            if (userStore.user && !feature.searchSessionId) {
                feature.searchSessionId = buildUniqueUserWebSessionId(
                    userStore.user.itemId,
                    'contact_finder',
                );
            }

            feature.setFlyoutIsOpen(true);

            if (feature.results.metadata.usedCompanyName !== company.name) {
                feature.companyId = company.id;
                feature.filters = { companyName: company.name };

                await feature.findAndSetContactsByFilter(
                    { companyName: company.name },
                    1,
                    dealId,
                );
            }
        },

        addProfileToProfiles: (profile: string) => {
            feature.addedProfiles.push(profile);
        },

        trackContactCreated: (
            dealId: string,
            customInput: CustomInput,
            contactIndex: number,
        ) => {
            if (!feature.searchSessionId) {
                return;
            }

            const fieldsProvidedSorted = (
                customInput.customValues.map(
                    (field) => field.name,
                ) as PredefinedCustomFields[]
            ).sort((a, b) => a.localeCompare(b));

            mixpanelService.trackEvent(
                MixpanelEventName.CreatedContactFromContactFinder,
                {
                    contactFinderSearchSession: feature.searchSessionId,
                    contactFinderParametersProvided: fieldsProvidedSorted,
                    contactFinderContactCreationIndex: contactIndex + 1,
                    contactFinderPage: feature.results.metadata.page,
                    contactFinderTotalResults: feature.results.metadata.total,
                    contactFinderFilter: JSON.stringify(feature.filters),
                    companyId: feature.companyId,
                },
                dealId,
            );
        },

        trackSearchWithContactFinder: (dealId: string) => {
            if (!feature.searchSessionId) {
                return;
            }

            mixpanelService.trackEvent(
                MixpanelEventName.SearchedContactFinder,
                {
                    contactFinderSearchSession: feature.searchSessionId,
                    contactFinderFilter: JSON.stringify(feature.filters),
                    contactFinderPage: feature.results.metadata.page,
                    contactFinderTotalResults: feature.results.metadata.total,
                    companyId: feature.companyId,
                },
                dealId,
            );
        },
    };

    makeAutoObservable(feature);

    return feature;
};
