import {
    SortingKey,
    SortingOperator,
    UserSettingName,
} from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { PerformanceViewVisualizationLayout } from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';

export const DEFAULT_CSV_DELIMITER = ';';

export interface Settings {
    defaultPipeline: string | null;
    csvDelimiter: string;
    commentMentionEmailsEnabled: boolean;
    emailDigest: string;
    emailDealAssigneeChange: boolean;
    reminderEmailsEnabled: boolean;
    pauseDealEmailsEnabled: boolean;
    pipelineViewPreferencesAssignees: number[] | null;
    pipelineViewPreferencesShowPausedDeals: boolean | null;
    pipelineViewPreferencesCustomParameterTag: Record<string, string[]> | null;
    pipelineViewPreferencesVisualizationMode: PerformanceViewVisualizationLayout | null;
    pipelineViewPreferencesPaginationRows: number | null;
    pipelineViewPreferencesSorting: SortingSetting[] | null;
    pipelineViewPreferencesStageIds: string[] | null;
    optInAchievements: boolean;
    outreachBlockIds: string[] | null;
    outreachCallBlockIds: string[] | null;
}

export const userSettingsMap: Record<UserSettingName, keyof Settings> = {
    [UserSettingName.CsvDelimiter]: 'csvDelimiter',
    [UserSettingName.DefaultPipeline]: 'defaultPipeline',
    [UserSettingName.EmailCommentMentions]: 'commentMentionEmailsEnabled',
    [UserSettingName.EmailDigest]: 'emailDigest',
    [UserSettingName.EmailReminders]: 'reminderEmailsEnabled',
    [UserSettingName.EmailPausedDealNotifications]: 'pauseDealEmailsEnabled',
    [UserSettingName.PipelineViewPreferencesAssignees]:
        'pipelineViewPreferencesAssignees',
    [UserSettingName.PipelineViewPreferencesShowPausedDeals]:
        'pipelineViewPreferencesShowPausedDeals',
    [UserSettingName.PipelineViewPreferencesCustomParameterTag]:
        'pipelineViewPreferencesCustomParameterTag',
    [UserSettingName.PipelineViewPreferencesVisualizationMode]:
        'pipelineViewPreferencesVisualizationMode',
    [UserSettingName.PipelineViewPreferencesPaginationRows]:
        'pipelineViewPreferencesPaginationRows',
    [UserSettingName.PipelineViewPreferencesSorting]:
        'pipelineViewPreferencesSorting',
    [UserSettingName.PipelineViewPreferencesStageIds]:
        'pipelineViewPreferencesStageIds',
    [UserSettingName.OptInAchievements]: 'optInAchievements',
    [UserSettingName.OutreachBlockIds]: 'outreachBlockIds',
    [UserSettingName.OutreachCallBlockIds]: 'outreachCallBlockIds',
    [UserSettingName.EmailDealAssigneeChange]: 'emailDealAssigneeChange',
};

/**
 * A type which holds the possible types when managing settings/preferences.
 */
export type SettingsValueType =
    | boolean
    | string
    | string[]
    | number
    | number[]
    | Record<string, string[]>
    | SortingSetting[];

export type SortingSetting = { field: SortingKey; sop: SortingOperator };

const isSortingSetting = (value: unknown): value is SortingSetting => {
    if (typeof value !== 'object' || value === null) {
        return false;
    }

    const { field, sop } = value as any;

    return (
        'field' in value &&
        'sop' in value &&
        typeof field === 'string' &&
        typeof sop === 'string' &&
        Object.values(SortingKey).includes(field as SortingKey) &&
        Object.values(SortingOperator).includes(sop as SortingOperator)
    );
};

export const isSortingSettingArray = (
    value: unknown,
): value is SortingSetting[] => {
    return Array.isArray(value) && value.every(isSortingSetting);
};
