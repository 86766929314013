import * as Sentry from '@sentry/react';
import { DocumentNode, GraphQLError } from 'graphql';
import { GraphQLClient, RequestOptions, Variables } from 'graphql-request';
import { Client, SubscribePayload, createClient } from 'graphql-ws';

import { getSdk as getProcessSdk } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { getSdk as getProcessMultipartSdk } from 'src/data/api/graphql/br_process_multipart/generated/graphql-sdk';
import { getSdk as getProjectSdk } from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import { getSdk as getSearchSdk } from 'src/data/api/graphql/br_search/generated/graphql-sdk';
import { getSdk as getUserSdk } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { assignError } from 'src/utils/error.utils';
import { isLocalDevelopment } from 'src/utils/is-local-development.utils';

import { Requester } from './br_process/generated/graphql-sdk';
import { WsSubscriptionClosedError } from './custom-errors/ws-subscription-closed-error';
import { getCookie } from 'src/utils/cookie.utils';
import { RequestMiddleware } from 'node_modules/graphql-request/build/esm/types';

type GraphQLRequestOptions = {
    signal: AbortSignal;
};

const getExtendedGraphqlSdk = <A>(
    gqlClient: GraphQLClient,
    getSdk: <C>(requester: Requester<C>) => A,
    wsClient?: Client,
) => {
    const requester: Requester<{ signal: AbortSignal }> = <
        R,
        V extends Variables = Variables,
    >(
        doc: DocumentNode,
        vars?: any,
        options?: GraphQLRequestOptions,
    ): Promise<R> => {
        return gqlClient.request<R, V>({
            document: doc,
            variables: vars,
            signal: options?.signal,
        } as unknown as RequestOptions<V, R> as any);
    };

    /* T as Subscription types, e.g. ContactExtractionGetContactsInDealSubscription */
    const runSubscription = <T>(
        payload: SubscribePayload,
        onNextValue: (value: T) => void,
        onSubscriptionCompleted: () => void,
        onSubscriptionError: (errorMessage: Error) => void,
        signal: AbortSignal,
    ) => {
        if (signal.aborted) {
            throw new Error('Aborted');
        }
        if (!wsClient) {
            console.error('WS Client not defined');
            return;
        }
        const cleanup = wsClient.subscribe<T>(payload, {
            next: (result) => {
                if (result.data) {
                    onNextValue(result.data);
                }
            },
            error: (error: Error | GraphQLError[] | CloseEvent) => {
                let capturedError: Error | WsSubscriptionClosedError;

                if (error instanceof Error) {
                    capturedError = assignError(error);
                } else if (Array.isArray(error)) {
                    capturedError = new Error(
                        error.map((err) => err.message).join(' / '),
                    );
                } else {
                    console.error(
                        'WebSocket JSON current target:',
                        JSON.stringify(error.currentTarget),
                    );
                    console.error(
                        'WebSocket JSON target:',
                        JSON.stringify(error.target),
                    );
                    console.error(
                        'JSON closedEvent from ws subscription',
                        JSON.stringify(error),
                    );
                    console.error('closedEvent from ws subscription', error);
                    capturedError = new WsSubscriptionClosedError(
                        'The subscription connection was closed due an error CloseEvent',
                        error,
                        JSON.stringify(error),
                    );
                }
                onSubscriptionError(capturedError);
                Sentry.captureException(capturedError);
                cleanup?.();
            },
            complete: () => {
                if (!signal.aborted) {
                    onSubscriptionCompleted();
                }
                cleanup?.();
            },
        });
        signal.onabort = () => {
            cleanup?.();
        };
        return cleanup;
    };

    return {
        ...getSdk<GraphQLRequestOptions>(requester),
        runSubscription: runSubscription,
        rawGraphqlRequest: <R>(
            query: string | DocumentNode,
            variables?: Variables,
            options?: GraphQLRequestOptions,
        ) =>
            gqlClient.request<R>({
                document: query,
                variables,
                signal: options?.signal as RequestOptions['signal'],
            }),
    };
};

const processUrl = '/apps/process/graphql';
const processMultipartUrl = '/apps/process/graphql_multipart';
const projectUrl = '/apps/project/graphql';
const userUrl = '/apps/user/graphql';
const searchUrl = '/apps/search/graphql';

const platform = import.meta.env.VITE_API_PLATFORM || 'beta1';

const graphqlUserUrl = `${
    isLocalDevelopment() ? 'http' : 'https'
}://${window.location.host}${userUrl}`;

const graphqlProjectUrl = `${
    isLocalDevelopment() ? 'http' : 'https'
}://${window.location.host}${projectUrl}`;

const graphqlProcessUrl = `${
    isLocalDevelopment() ? 'http' : 'https'
}://${window.location.host}${processUrl}`;

const graphqlProcessMultipartUrl = `${
    isLocalDevelopment() ? 'http' : 'https'
}://${window.location.host}${processMultipartUrl}`;

const graphqlSearchUrl = `${
    isLocalDevelopment() ? 'http' : 'https'
}://${window.location.host}${searchUrl}`;

/**
 * Adds X-XSRF-TOKEN to the headers of each outgoing request.
 * @param {Request} request The request to be modified.
 * @returns {Promise<Request>} The modified request.
 */
const requestMiddleware: RequestMiddleware = async (request) => {
    const xsrfToken = getCookie('XSRF-TOKEN');
    return {
        ...request,
        headers: {
            ...request.headers,
            'X-XSRF-TOKEN': xsrfToken,
        },
    };
};

//this is a way to setup the graphqlWsUrl for local development
export const graphqlProcessWsUrl = isLocalDevelopment()
    ? `wss://${platform}.buildingradar.com${processUrl}`
    : `wss://${window.location.host}${processUrl}`;

const graphqlSearchWsUrl = isLocalDevelopment()
    ? `wss://${platform}.buildingradar.com${searchUrl}`
    : `wss://${window.location.host}${searchUrl}`;

const buildBrProcessGraphqlClientSdk = () => {
    return getExtendedGraphqlSdk(
        new GraphQLClient(graphqlProcessUrl, {
            requestMiddleware,
        }),
        getProcessSdk,
        createClient({
            url: graphqlProcessWsUrl,
        }),
    );
};

const buildBrProcessMultipartGraphqlClientSdk = () => {
    return getExtendedGraphqlSdk(
        new GraphQLClient(graphqlProcessMultipartUrl, {
            requestMiddleware,
        }),
        getProcessMultipartSdk,
    );
};

const buildBrUserGraphqlClientSdk = () =>
    getExtendedGraphqlSdk(
        new GraphQLClient(graphqlUserUrl, {
            requestMiddleware,
        }),
        getUserSdk,
    );

const buildBrSearchGraphqlClientSdk = () =>
    getExtendedGraphqlSdk(
        new GraphQLClient(graphqlSearchUrl, {
            requestMiddleware,
        }),
        getSearchSdk,
        createClient({
            url: graphqlSearchWsUrl,
        }),
    );

const buildBrProjectGraphqlClientSdk = () =>
    getExtendedGraphqlSdk(new GraphQLClient(graphqlProjectUrl), getProjectSdk);

/**
 * The SDK used to interact to our br_process backend endpoints.
 */
export const ProcessGqlSdkWrapper = buildBrProcessGraphqlClientSdk();
export type ProcessGqlSdk = typeof ProcessGqlSdkWrapper;

/**
 * The SDK used to interact to our br_process backend endpoints with CSRF (for multipart requests security) enabled.
 */
export const ProcessMultipartGqlSdkWrapper =
    buildBrProcessMultipartGraphqlClientSdk();
export type ProcessMultipartGqlSdk = typeof ProcessMultipartGqlSdkWrapper;

/**
 * The SDK used to interact to our br_user backend endpoints.
 */
export const UserGqlSdkWrapper = buildBrUserGraphqlClientSdk();
export type UserGqlSdk = typeof UserGqlSdkWrapper;

/**
 * The SDK used to interact to our br_search_user backend endpoints.
 */
export const SearchGqlSdkWrapper = buildBrSearchGraphqlClientSdk();
export type SearchGqlSdk = typeof SearchGqlSdkWrapper;

/**
 * The SDK used to interact to our br_project backend endpoints.
 */
export const ProjectGqlSdkWrapper = buildBrProjectGraphqlClientSdk();
export type ProjectGqlSdk = typeof ProjectGqlSdkWrapper;
