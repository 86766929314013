import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC } from 'react';

import { Link } from 'src/presentation/shared/link/link.component';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import {
    StyledBottomCorner,
    StyledItemShadow as StyledNavItemShadow,
} from './nav-item.styled';
import { Localized } from 'src/presentation/shared/localized/localized';
import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { cn } from 'src/utils/tailwindcss.utils';

interface NavItemProps {
    name: string;
    className: string;
    icon: JSX.Element;
    route: string;
    isActive: boolean;
    isShadowVisible?: boolean;
    hideTooltip?: boolean;
    showNewBadge?: boolean;
    onClick?: () => void;
}

export const NavItemComponent: FC<NavItemProps> = observer(
    ({
        name,
        icon,
        route,
        isActive,
        className,
        isShadowVisible = false,
        showNewBadge = false,
        onClick,
    }) => {
        const isTablet = useMediaQuery(MediaQuery.tablet);

        return (
            <Tooltip side="right" content={name} triggerAsChild>
                <div
                    className={cn(
                        'bg-navbar-default group relative flex h-16 w-full cursor-pointer items-center justify-center',
                        {
                            'bg-navbar-active': isActive,
                            'before:bg-navbar-active before:absolute before:-top-3 before:right-0 before:size-3 before:content-[""]':
                                isActive,
                            'after:bg-navbar-default after:absolute after:-top-3 after:right-0 after:size-3 after:rounded-br-2xl after:content-[""]':
                                isActive,
                        },
                        className,
                    )}
                    onClick={onClick}
                >
                    {isActive && (
                        <>
                            <StyledBottomCorner />
                            <StyledNavItemShadow
                                hasAnimation={!isTablet}
                                isVisible={isShadowVisible}
                            />
                        </>
                    )}
                    <Link href={route}>
                        <div className="group-hover flex size-full items-center justify-center text-center text-sm hover:text-indigo-600">
                            {icon}
                        </div>
                        {showNewBadge && (
                            <div className="absolute bottom-2 right-0.5 z-10 flex h-4 w-auto flex-row items-center justify-center rounded bg-pink-500 px-1 py-0.5 text-xs text-white">
                                <Localized>common.new</Localized>
                            </div>
                        )}
                    </Link>
                </div>
            </Tooltip>
        );
    },
);
