export const GrayIcon = () => {
    return (
        <svg
            width="72"
            height="72"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_ddd_806_2498)">
                <path
                    d="M21 47.25H22.2926C24.922 47.25 27.5015 46.533 29.7537 45.1761C32.0058 43.8191 33.8453 41.8737 35.0741 39.5492L35.25 39.75L37.5 42L41.9993 41.9994L40.5 45.75L42 49.5L42.1038 49.7427C39.0963 51.0665 35.8463 51.7501 32.5603 51.75H21V47.25Z"
                    fill="#D0D0D0"
                />
                <path
                    d="M42.1038 49.7425C44.5945 48.6464 46.8777 47.1293 48.8531 45.2578C51.1897 43.0441 53.0507 40.3775 54.3224 37.4207C55.5942 34.4639 56.2501 31.2789 56.25 28.0602V23.25H36.75V32.7925C36.75 34.9051 36.287 36.9919 35.3936 38.9062C35.2921 39.1239 35.1856 39.3381 35.0741 39.5489L35.25 39.75L37.5 42L41.9993 41.9994L40.5 45.75L42 49.5L42.1038 49.7425Z"
                    fill="#E8E8E8"
                />
                <path
                    d="M41.5802 48.45L40.5 45.75L41.9993 41.9994L37.5 42L35.25 39.75L35.0742 39.5492C33.8454 41.8737 32.0059 43.8191 29.7537 45.1761C27.5016 46.533 24.922 47.25 22.2926 47.25H21V51.75H29.5603C33.7939 51.774 37.9524 50.6323 41.5802 48.45Z"
                    fill="#E8E8E8"
                />
                <path
                    d="M35.3936 38.9062C35.2921 39.1239 35.1857 39.3382 35.0742 39.549L35.25 39.75L37.5 42L41.9993 41.9994L40.5 45.75L41.5802 48.45C45.1417 46.3723 48.0946 43.3949 50.1428 39.8162C52.1909 36.2376 53.2625 32.1835 53.25 28.0602V23.25H36.75V32.7925C36.75 34.9051 36.287 36.9919 35.3936 38.9062Z"
                    fill="url(#paint0_linear_806_2498)"
                />
                <path
                    d="M56.9692 17.2498H35.9692C35.1408 17.2498 34.4692 17.9214 34.4692 18.7498V21.7498C34.4692 22.5782 35.1408 23.2498 35.9692 23.2498H56.9692C57.7976 23.2498 58.4692 22.5782 58.4692 21.7498V18.7498C58.4692 17.9214 57.7976 17.2498 56.9692 17.2498Z"
                    fill="url(#paint1_linear_806_2498)"
                />
                <path
                    d="M57 17.253C56.9895 17.253 56.9798 17.2498 56.9692 17.2498H35.9692C35.5714 17.2498 35.1898 17.4079 34.9085 17.6892C34.6272 17.9705 34.4692 18.352 34.4692 18.7498V21.7467C34.4797 21.7467 34.4894 21.7498 34.5 21.7498H55.5C55.8978 21.7498 56.2794 21.5918 56.5607 21.3105C56.842 21.0292 57 20.6476 57 20.2498V17.253Z"
                    fill="url(#paint2_linear_806_2498)"
                />
                <path
                    d="M27 51.75H30V45.0241C29.0586 45.6173 28.052 46.1 27 46.4625V51.75Z"
                    fill="#BBBBBB"
                />
                <path
                    d="M33.75 41.5969C33.2941 42.1915 32.7926 42.7497 32.25 43.2664V45H33.75V41.5969Z"
                    fill="#BBBBBB"
                />
                <path
                    d="M32.25 46.4998H33.75V47.9998H32.25V46.4998Z"
                    fill="#BBBBBB"
                />
                <path
                    d="M32.25 51.75H32.5603C32.958 51.75 33.3547 51.7387 33.75 51.7189V49.5H32.25V51.75Z"
                    fill="#BBBBBB"
                />
                <path
                    d="M19.5 45.75C19.1022 45.75 18.7206 45.908 18.4393 46.1893C18.158 46.4706 18 46.8522 18 47.25V46.5C18 45.9033 17.7629 45.331 17.341 44.909C16.919 44.4871 16.3467 44.25 15.75 44.25C15.1533 44.25 14.581 44.4871 14.159 44.909C13.7371 45.331 13.5 45.9033 13.5 46.5V52.5C13.5 53.0967 13.7371 53.669 14.159 54.091C14.581 54.5129 15.1533 54.75 15.75 54.75C16.3467 54.75 16.919 54.5129 17.341 54.091C17.7629 53.669 18 53.0967 18 52.5V51.75C18 52.1478 18.158 52.5294 18.4393 52.8107C18.7206 53.092 19.1022 53.25 19.5 53.25C19.8978 53.25 20.2794 53.092 20.5607 52.8107C20.842 52.5294 21 52.1478 21 51.75V47.25C21 46.8522 20.842 46.4706 20.5607 46.1893C20.2794 45.908 19.8978 45.75 19.5 45.75Z"
                    fill="url(#paint3_linear_806_2498)"
                />
                <path
                    d="M36.75 23.2498H56.25V26.2498H36.75V23.2498Z"
                    fill="#7E8589"
                />
                <path
                    d="M34.5 19.4998H39V20.9998H34.5V19.4998Z"
                    fill="#BBBBBB"
                />
                <path d="M54 19.4998H58.5V20.9998H54V19.4998Z" fill="#BBBBBB" />
                <path d="M48 19.4998H52.5V20.9998H48V19.4998Z" fill="#BBBBBB" />
                <path
                    d="M40.5 19.4998H45V20.9998H40.5V19.4998Z"
                    fill="#BBBBBB"
                />
                <path
                    d="M36.75 23.2498H56.25V26.2498H36.75V23.2498Z"
                    fill="#D0D0D0"
                />
                <path
                    d="M36.75 23.2498H53.25V26.2498H36.75V23.2498Z"
                    fill="#E8E8E8"
                />
            </g>
            <defs>
                <filter
                    id="filter0_ddd_806_2498"
                    x="0"
                    y="1"
                    width="72"
                    height="72"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="6" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_806_2498"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_806_2498"
                        result="effect2_dropShadow_806_2498"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect2_dropShadow_806_2498"
                        result="effect3_dropShadow_806_2498"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect3_dropShadow_806_2498"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_806_2498"
                    x1="42.452"
                    y1="33.5634"
                    x2="50.5798"
                    y2="46.4078"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_806_2498"
                    x1="44.2111"
                    y1="19.7054"
                    x2="44.6935"
                    y2="23.9331"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_806_2498"
                    x1="43.6147"
                    y1="19.0915"
                    x2="43.9051"
                    y2="22.2771"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_806_2498"
                    x1="16.5443"
                    y1="48.5472"
                    x2="19.9449"
                    y2="53.8692"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#E1E1E1" />
                </linearGradient>
            </defs>
        </svg>
    );
};
