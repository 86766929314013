import { makeAutoObservable } from 'mobx';
import {
    AddOrRemovePromptBlockPayload,
    BlockSettingTrackingAction,
    DeletePromptBlockPayload,
    OutreachPromptBlocksByType,
    UpsertPromptBlockPayload,
} from 'src/app-features/ai-value-proposition-outreach/domain/model/outreach-block.model';
import { OutreachBlockType as SdkOutreachBlockType } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { OutreachType } from 'src/app-features/ai-value-proposition-outreach/domain/model/outreach-type';
import { UserStore } from 'src/data/stores/user/user.store';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { UsersApi } from 'src/data/api/user/user.api';
import { UserSettingName } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { IPromptBlockApi } from '../api/prompt-block.api';
import { IVpoGenerationStore } from './vpo-generation.store';
import { OutreachBlockTypeToSdk } from './model/outreach-block-type';
import { userSettingsMap } from 'src/domain/models/settings/settings.model';
import { IDealActivityStore } from 'src/app-features/deal-activity/domain/deal-activity.store';

export interface IPromptBlocksManagementFeature {
    /**
     * Object containing the configured prompt blocks for the account.
     */
    accountConfigurationPromptBlocks: OutreachPromptBlocksByType;
    /**
     * Object containing the email prompt blocks linked to the user settings.
     */
    userSettingsEmailPromptBlocks: OutreachPromptBlocksByType;
    /**
     * Object containing the call script prompt blocks linked to the user settings.
     */
    userSettingsCallScriptPromptBlocks: OutreachPromptBlocksByType;
    dirtyState: boolean;
    isSavingSettings: boolean;
    isSavingPromptBlock: boolean;
    addPromptBlockOnUserSettings: (
        payload: AddOrRemovePromptBlockPayload,
    ) => Promise<void>;
    removePromptBlockFromUserSettings: (
        payload: AddOrRemovePromptBlockPayload,
    ) => Promise<void>;
    upsertPromptBlock: (payload: UpsertPromptBlockPayload) => Promise<void>;
    deletePromptBlock: (payload: DeletePromptBlockPayload) => Promise<void>;
}

export class PromptBlocksManagementFeature
    implements IPromptBlocksManagementFeature
{
    dirtyState = false;
    isSavingSettings = false;
    isSavingPromptBlock = false;

    constructor(
        private userApi: UsersApi,
        private promptBlockApi: IPromptBlockApi,
        private mixpanelService: MixpanelService,
        private accountConfigurationsStore: IAccountConfigurationStore,
        private userStore: UserStore,
        private vpoGenerationStore: IVpoGenerationStore,
        private baseStore: IBaseStore,
        private dealActivityStore: IDealActivityStore,
    ) {
        makeAutoObservable(this);
    }

    get accountConfigurationPromptBlocks(): OutreachPromptBlocksByType {
        return this.accountConfigurationsStore.accountPromptBlocksConfig;
    }

    get userSettingsEmailPromptBlocks(): OutreachPromptBlocksByType {
        const userPromptBlockIds =
            this.userStore.user?.settings.outreachBlockIds ?? [];
        return this.getMapOfAccountBlocksGroupedByCategory(userPromptBlockIds);
    }

    get userSettingsCallScriptPromptBlocks(): OutreachPromptBlocksByType {
        const userPromptBlockIds =
            this.userStore.user?.settings.outreachCallBlockIds ?? [];

        return this.getMapOfAccountBlocksGroupedByCategory(userPromptBlockIds);
    }

    onPromptBlocksSettingsUpdated = (
        newBlockIds: string[],
        settingName: UserSettingName,
    ) => {
        const user = this.userStore.user;
        if (user) {
            const settingPropName =
                userSettingsMap[settingName as UserSettingName];
            this.dirtyState = true;
            user.settings = {
                ...user.settings,
                [settingPropName]: newBlockIds,
            };
        }
    };

    addPromptBlockOnUserSettings = async (
        payload: AddOrRemovePromptBlockPayload,
    ) => {
        try {
            const { blockId, dealId, type, propositionType } = payload;

            const userSettingPromptBlocks =
                propositionType === 'call-script'
                    ? this.userSettingsCallScriptPromptBlocks
                    : this.userSettingsEmailPromptBlocks;

            const blocks: string[] = [
                ...userSettingPromptBlocks.writingStyle.map((b) => b.id),
                ...userSettingPromptBlocks.desiredOutcome.map((b) => b.id),
                ...userSettingPromptBlocks.valueProposition.map((b) => b.id),
                blockId,
            ];

            const settingName =
                propositionType === 'call-script'
                    ? UserSettingName.OutreachCallBlockIds
                    : UserSettingName.OutreachBlockIds;

            await handleRequestAsync(
                this.userApi.changeUserSetting,
                { setting: settingName, value: blocks },
                this.setIsSavingSettings,
            );

            this.onPromptBlocksSettingsUpdated(blocks, settingName);

            this.trackPromptSettingsEdition({
                dealId,
                promptBlockId: blockId,
                outreachType: OutreachType.email,
                promptBlockName:
                    this.accountConfigurationPromptBlocks[type].find(
                        (b) => b.id === blockId,
                    )?.title ?? '',
                promptBlockSection: OutreachBlockTypeToSdk[type],
                vpoSettingsEditAction: BlockSettingTrackingAction.added,
            });
        } catch (error) {
            this.baseStore.onRequestFailed(
                'add-prompt-block-on-user-settings',
                error as Error,
            );
            throw error;
        }
    };

    removePromptBlockFromUserSettings = async (
        payload: AddOrRemovePromptBlockPayload,
    ) => {
        try {
            const { blockId, dealId, type, propositionType } = payload;

            const currentUserOutreachBlockIds =
                propositionType === 'call-script'
                    ? this.userStore.user?.settings.outreachCallBlockIds
                    : this.userStore.user?.settings.outreachBlockIds;

            const filteredPromptBlocks = (
                currentUserOutreachBlockIds ?? []
            ).filter((b) => b !== blockId);

            const settingName =
                propositionType === 'call-script'
                    ? UserSettingName.OutreachCallBlockIds
                    : UserSettingName.OutreachBlockIds;

            await handleRequestAsync(this.userApi.changeUserSetting, {
                setting: settingName,
                value: filteredPromptBlocks,
            });

            this.onPromptBlocksSettingsUpdated(
                filteredPromptBlocks,
                settingName,
            );

            this.trackPromptSettingsEdition({
                dealId,
                promptBlockId: blockId,
                outreachType: OutreachType.email,
                promptBlockName:
                    this.accountConfigurationPromptBlocks[type].find(
                        (b) => b.id === blockId,
                    )?.title ?? '',
                promptBlockSection: OutreachBlockTypeToSdk[type],
                vpoSettingsEditAction: BlockSettingTrackingAction.removed,
            });
        } catch (error) {
            this.baseStore.onRequestFailed(
                'remove-prompt-block-from-user-settings',
                error as Error,
            );
            throw error;
        }
    };

    upsertPromptBlock = async (payload: UpsertPromptBlockPayload) => {
        try {
            const { propositionType, ...apiParams } = payload;
            const blockId = await handleRequestAsync(
                this.promptBlockApi.upsertOutreachBlock,
                {
                    ...apiParams,
                    type: OutreachBlockTypeToSdk[payload.type],
                    vpoSessionId: this.vpoGenerationStore.vpoSessionId ?? '',
                },
                this.setIsSavingPromptBlock,
            );

            if (!payload.id && blockId) {
                await this.addPromptBlockOnUserSettings({
                    blockId,
                    type: payload.type,
                    dealId: payload.dealId,
                    propositionType,
                });
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'upsert-prompt-block',
                error as Error,
            );
            throw error;
        }
    };

    deletePromptBlock = async (payload: DeletePromptBlockPayload) => {
        try {
            const { propositionType, ...apiParams } = payload;
            await handleRequestAsync(
                this.promptBlockApi.deleteOutreachBlock,
                {
                    ...apiParams,
                    vpoSessionId: this.vpoGenerationStore.vpoSessionId ?? '',
                },
                this.setIsSavingPromptBlock,
            );

            const hasToDeleteFromUserSettings =
                (
                    this.userStore.user?.settings.outreachBlockIds ?? []
                ).findIndex((id) => id === payload.id) >= 0;

            if (hasToDeleteFromUserSettings) {
                await this.removePromptBlockFromUserSettings({
                    blockId: payload.id,
                    type: payload.type,
                    dealId: payload.dealId,
                    propositionType,
                });
            }
        } catch (error) {
            this.baseStore.onRequestFailed(
                'delete-prompt-block',
                error as Error,
            );
            throw error;
        }
    };

    private setIsSavingSettings = (loading: boolean) =>
        (this.isSavingSettings = loading);

    private setIsSavingPromptBlock = (loading: boolean) =>
        (this.isSavingPromptBlock = loading);

    private trackPromptSettingsEdition = (data: {
        dealId: string;
        promptBlockId: string;
        outreachType: OutreachType;
        promptBlockSection: SdkOutreachBlockType;
        promptBlockName: string;
        vpoSettingsEditAction: BlockSettingTrackingAction;
    }) => {
        const {
            dealId,
            promptBlockId,
            outreachType,
            promptBlockName,
            promptBlockSection,
            vpoSettingsEditAction,
        } = data;
        this.mixpanelService.trackEvent(
            MixpanelEventName.EditedVpoPromptSettings,
            {
                promptBlockId,
                outreachType,
                promptBlockName,
                promptBlockSection,
                vpoSettingsEditAction,
                outreachModalSessionId: this.dealActivityStore.sessionId,
            },
            dealId,
        );
    };

    private getMapOfAccountBlocksGroupedByCategory = (
        userBlockIds: string[],
    ) => {
        return {
            valueProposition:
                this.accountConfigurationPromptBlocks.valueProposition.filter(
                    (b) => userBlockIds.includes(b.id),
                ),
            desiredOutcome:
                this.accountConfigurationPromptBlocks.desiredOutcome.filter(
                    (b) => userBlockIds.includes(b.id),
                ),
            writingStyle:
                this.accountConfigurationPromptBlocks.writingStyle.filter((b) =>
                    userBlockIds.includes(b.id),
                ),
        };
    };
}
