import { makeAutoObservable } from 'mobx';

import { CustomParameter } from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { sortByOrder } from 'src/utils/array.utils';

export interface CustomParametersStore {
    parametersMap: Map<string, CustomParameter>;
    orderedParamNames: string[];
    hiddenParamNames: string[];
    setParameters: (parameters: CustomParameter[]) => void;
    setParameterValue: (name: string, value: string) => void;
    getParameter: (name: string) => CustomParameter | undefined;
    clearParameters: () => void;
}

export class CustomParametersStoreImpl implements CustomParametersStore {
    parametersMap: Map<string, CustomParameter> = new Map<
        string,
        CustomParameter
    >();
    orderedParamNames: string[] = [];
    hiddenParamNames: string[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    setParameters = (parameters: CustomParameter[]) => {
        this.setParametersOrder(parameters);
        parameters.forEach((param) => {
            this.parametersMap.set(param.name, param);
        });
    };

    setParametersOrder(parameters: CustomParameter[]) {
        this.orderedParamNames = [];
        this.hiddenParamNames = [];
        sortByOrder(parameters).forEach((param) => {
            if (param.isVisibleInDealPage) {
                this.orderedParamNames.push(param.name);
            } else {
                this.hiddenParamNames.push(param.name);
            }
        });
    }

    setParameterValue(name: string, value: string) {
        const param = this.parametersMap.get(name);
        if (!param) {
            return;
        }
        param.value = value;
        this.parametersMap.set(name, param);
    }

    getParameter(name: string) {
        return this.parametersMap.get(name);
    }

    clearParameters() {
        this.orderedParamNames = [];
        this.parametersMap.clear();
    }
}
