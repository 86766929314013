import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { NavItemComponent } from 'src/presentation/modules/primary-nav-bar/components/nav-item/nav-item.component';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { useCurrentRoute } from 'src/presentation/modules/router/utils/use-route-data.hook';
import { MediaQuery } from 'src/resources/styles/media.mixins';

import { ArrowStyled } from './other-section.styled';
import { OtherRoutes, readableOtherRoutes } from './other-section.util';
import { FeatureFlagControl } from 'src/presentation/shared/feature-flag-control/feature-flag-control.component';
import { FeatureFlag } from 'src/data/services/feature-flags/feature-flags.model';
import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { Button } from '@buildingradar/ui_library/button';
import { Bell, BellRing, HelpCircleIcon, Settings } from 'lucide-react';
import { cn } from 'src/utils/tailwindcss.utils';

interface OtherSectionProps {
    areNotificationsOpened: boolean;
    showNotificationsMarker: boolean;
    toggleNotifications: (flag: boolean) => void;
}

export const OtherSectionComponent: FC<OtherSectionProps> = observer(
    ({
        areNotificationsOpened,
        showNotificationsMarker,
        toggleNotifications,
    }) => {
        const { t } = useTranslation();

        const toggleNotificationsOpened = useCallback(
            () => toggleNotifications(!areNotificationsOpened),
            [areNotificationsOpened, toggleNotifications],
        );

        const isTablet = useMediaQuery(MediaQuery.tablet);

        const currentRoute = useCurrentRoute();
        const isGeneralSearchActive = currentRoute === AppRoute.generalSearch;
        const isSettingsActive =
            currentRoute?.startsWith(AppRoute.settings) ?? false;

        return (
            <div className="bg-navbar-active w-full">
                <FeatureFlagControl featureKey={FeatureFlag.neuralSearch} not>
                    <NavItemComponent
                        className="project-lookup"
                        name={t(readableOtherRoutes[OtherRoutes.searches])}
                        icon={
                            <BrIcon
                                iconName={IconName.BrSearchProjectsIcon}
                                iconSize={32}
                                iconColor={
                                    isGeneralSearchActive
                                        ? 'var(--accent-link)'
                                        : 'var(--main-font)'
                                }
                            />
                        }
                        route={AppRoute.generalSearch}
                        isActive={isGeneralSearchActive}
                        isShadowVisible={areNotificationsOpened}
                    />
                </FeatureFlagControl>
                <Tooltip side="right" triggerAsChild content={t('navbar.notifications')}>
                    <Button
                        variant="ghost"
                        className="notifications bg-navbar-default group relative size-16 rounded-none p-1"
                        onClick={toggleNotificationsOpened}
                    >
                        {showNotificationsMarker && (
                            <div className="absolute right-3 top-3 size-2 rounded-full bg-red-500" />
                        )}
                        <ArrowStyled
                            hasAnimation={!isTablet}
                            isVisible={areNotificationsOpened}
                        />
                        {areNotificationsOpened ? (
                            <BellRing className="size-7 text-indigo-600 [&>path]:fill-indigo-600" />
                        ) : (
                            <Bell className="size-7 text-gray-800 group-hover:text-indigo-600" />
                        )}
                    </Button>
                </Tooltip>
                <Tooltip
                    side="right"
                    content={t('navbar.resource_center')}
                    triggerAsChild
                >
                    <Button
                        asChild
                        variant="ghost"
                        className="bg-navbar-default :hovr group size-16 rounded-none p-1"
                    >
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            className="resource-center-link"
                            href={t('navbar.resource_center.link')}
                        >
                            <HelpCircleIcon className="size-7 text-gray-800 group-hover:text-indigo-600" />
                        </a>
                    </Button>
                </Tooltip>
                <NavItemComponent
                    className="settings group"
                    name={t(readableOtherRoutes[OtherRoutes.settings])}
                    icon={
                        <Settings
                            className={cn(
                                'size-7 text-gray-800 group-hover:text-indigo-600',
                                {
                                    'text-indigo-600': isSettingsActive,
                                },
                            )}
                        />
                    }
                    route={AppRoute.settings}
                    isActive={isSettingsActive}
                    isShadowVisible={areNotificationsOpened}
                />
            </div>
        );
    },
);
