import { makeAutoObservable } from 'mobx';

import { IAccountConfigurationApi } from 'src/data/api/account-configuration/account-configuration.api';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { IAccountConfiguration } from 'src/domain/models/account-configuration/account-configuration.model';
import { IActionFeatureConfiguration } from 'src/domain/models/account-configuration/action-feature-configuration/action-feature-configuration.model';
import { ISalesPeriodConfiguration } from 'src/domain/models/account-configuration/sales-period-configuration/sales-period-configuration.model';
import { CustomParameterConfig } from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { doNothing } from 'src/utils/function.utils';
import { handleRequestAsync } from 'src/utils/handle-request.utils';

import { IAccountConfigurationFeature } from './account-configuration-interface.feature';
import { ActionFeaturesIdentifiers } from './helpers/action-feature.helper';

export class AccountConfigurationFeature
    implements IAccountConfigurationFeature
{
    constructor(
        private accountConfigurationApi: IAccountConfigurationApi,
        private accountConfigurationStore: IAccountConfigurationStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    accountConfigurationsInitialized = false;

    get actionFeaturesConfigurations(): IActionFeatureConfiguration[] {
        return this.accountConfigurationStore.actionFeaturesConfigurations;
    }

    get salesPeriodConfiguration(): ISalesPeriodConfiguration | undefined {
        return this.accountConfigurationStore.salesPeriodConfiguration;
    }

    get customParameterTag(): CustomParameterConfig | undefined {
        return this.accountConfigurationStore.customParameterTag;
    }

    get isEstimatedDealValueVisible(): boolean {
        return this.accountConfigurationStore.isEstimatedDealValueVisible;
    }

    get getCustomParameterByName() {
        return this.accountConfigurationStore.getCustomParameterByName;
    }

    onActionFeaturesConfigurationFetched = (
        configurations: IAccountConfiguration,
    ) => {
        this.accountConfigurationStore.setAccountConfiguration(configurations);
        this.accountConfigurationsInitialized = true;
    };

    fetchAccountConfigurations = async () => {
        const configurations = await handleRequestAsync(
            this.accountConfigurationApi.getAccountConfiguration,
            {},
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'fetch-action-features-configurations',
                    error,
                ),
        );

        if (configurations) {
            this.onActionFeaturesConfigurationFetched(configurations);
        }
    };

    get isActionFeatureEnabled() {
        return this.accountConfigurationStore.isActionFeatureEnabled;
    }

    // Action feature getters

    get isContactExtractorEnabled() {
        return this.isActionFeatureEnabled(
            ActionFeaturesIdentifiers.contact_extractor,
        );
    }
    get isVPOEnabled() {
        return this.isActionFeatureEnabled(
            ActionFeaturesIdentifiers.value_prop_outreach,
        );
    }
    get isXrayEnabled() {
        return this.isActionFeatureEnabled(ActionFeaturesIdentifiers.xray);
    }
}
