import { gql } from 'graphql-tag';
import { encode } from 'js-base64';

import {
    PipelineStageStatisticsPartFragmentDoc,
    FilterKeys,
    FilterOperators,
    FilterOpQl,
    DealState,
    PipelineStatisticsDealPartFragmentDoc,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { buildQueryStringFromFilterList } from 'src/data/api/utils/queryFilterBuilder.utils';
import { CustomParameterConfig } from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { DynamicFilter } from 'src/domain/models/dynamic-filter/dynamic-filter.model';

import { PipelineViewPreferencesParams } from './pipeline.api';

/**
 * Stage ids may include chars which are not usable as a key in a json object.
 * This is used to get a safe string by converting string to a base64 string.
 * Base64 can be used as a key in a json object.
 * '=' is base64 padding key, not usable in a json object key, removed.
 * @param {string} stageId - id of the stage.
 */
export const getSafeStageId = (stageId: string) =>
    encode(stageId).replace(/=/g, '');

/**
 * Build the necessary query filters we need to fetch pipeline stage deals and also pipeline statistics.
 * @param preferences A object with the selected user view preferences.
 * @param fetchOnlyInPlayDeals If true, we only consider in play deals. When fetching statistics,
 * we should pass this as false, since we need to consider also disqualified deals
 * deals.
 */
export const buildQueryFilters = (
    preferences: PipelineViewPreferencesParams | undefined,
    customParameterTag: CustomParameterConfig | undefined,
    fetchOnlyInPlayDeals: boolean,
    searchTitle?: string,
) => {
    const filters: FilterOpQl[] = [];

    if (fetchOnlyInPlayDeals) {
        filters.push({
            field: FilterKeys.FkDealState,
            fop: FilterOperators.FopEq,
            value: DealState.InPlay,
        });
    }

    if (preferences) {
        const {
            usersIds,
            showPausedDeals,
            customParameterTagOptions,
            stageIds,
        } = preferences;

        if (usersIds?.length) {
            filters.push({
                field: FilterKeys.FkDealAssigneeBid,
                fop: FilterOperators.FopIn,
                values: usersIds,
            });
        }

        filters.push({
            field: FilterKeys.FkDealHidePaused,
            fop: FilterOperators.FopEq,
            value: !showPausedDeals,
        });

        if (customParameterTag && customParameterTagOptions.length) {
            const options = customParameterTagOptions.filter(
                (o) => o !== NoneOption,
            );
            const values: any[] = options.map((c) => ({
                name: customParameterTag.name,
                value: c,
            }));

            const noneOption = customParameterTagOptions.find(
                (c) => c === NoneOption,
            );
            if (noneOption) {
                values.push({ name: customParameterTag.name, value: '' });
                values.push({ name: customParameterTag.name, missing: true });
            }

            filters.push({
                field: FilterKeys.FkDealCustomValue,
                fop: FilterOperators.FopIn,
                values,
            });
        }

        if (stageIds?.length) {
            filters.push({
                field: FilterKeys.FkDealCurrentStageId,
                fop: FilterOperators.FopIn,
                values: stageIds,
            });
        }
    }

    if (searchTitle && searchTitle.length > 0) {
        filters.push({
            field: FilterKeys.FkDealTitle,
            fop: FilterOperators.FopRegex,
            value: `(?i)${searchTitle}`,
        });
    }

    return filters;
};

/**
 * We use stage id as an alias to allDeals query to easily determine deals of a particular stage upon response.
 * response[getSafeStageId(stageId)] = in-stage deals of the stage
 * @param {string} stageId - id of the stage.
 */
const getStageInPlayDealsQuery = (
    stageId: string,
    pipelineId: string,
    filters: FilterOpQl[],
    dynamicFilters: DynamicFilter[],
) => {
    /** Merging pipelineId and stageId to the other filter preferences coming from the filters argument */
    const inPlayFiltersPlusPreferences = [
        ...filters,
        {
            field: FilterKeys.FkDealPipelineId,
            fop: FilterOperators.FopEq,
            value: pipelineId,
        },
        {
            field: FilterKeys.FkDealCurrentStageId,
            fop: FilterOperators.FopEq,
            value: stageId,
        },
    ];

    const filteringString = buildQueryStringFromFilterList(
        inPlayFiltersPlusPreferences,
    );

    return `${getSafeStageId(
        stageId,
    )}: dealsCollection(queryParams: {pagination: {first: 50}, filtering: ${filteringString}, dynamicFiltering: [${dynamicFilters.join(
        ',',
    )}]}) {
        edges {
            node {
              ...PipelineStatisticsDealPart   
            }
        }
        totalCount
    }`;
};

export const getStageStatisticsQuery = (
    stageIds: string[],
    pipelineId: string,
    filters: FilterOpQl[],
    dynamicFilters: DynamicFilter[],
) => {
    return gql`query GetPipelineStatistics($pipelineId: String!, $filters: [FilterOpQL!]!, $dynamicFilters: [DynamicFilter!]!) {
      ${stageIds.map((id) => getStageInPlayDealsQuery(id, pipelineId, filters, dynamicFilters))}
      pipeline(pipelineId: $pipelineId) {
        statistics(queryParams: {filtering: $filters, dynamicFiltering: $dynamicFilters}) {
          stages {
            ...PipelineStageStatisticsPart
          }
        }
      }
    }
    ${PipelineStatisticsDealPartFragmentDoc}
    ${PipelineStageStatisticsPartFragmentDoc}`;
};

export const NoneOption = '__none__';
