import {
    CustomParameter,
    CustomParameterConfig,
} from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

/**
 * Filter util function which receives a list of custom parameters
 * and returns only valid one. Meaning it returns only the non nullable, string, date or decision types.
 * Dor the decision type parameters, only returns the ones with valid `accepted values`
 */
export const filterValidNonNullableParameters = (
    parameters: (CustomParameter | CustomParameterConfig | undefined)[],
) =>
    parameters
        .filter(isNonNullable)
        .filter(
            (p) =>
                p.type === 'STRING' ||
                p.type === 'ISODATE' ||
                (p.type === 'DECISION' &&
                    p.acceptedValues &&
                    p.acceptedValues.length > 0),
        );

export const CUSTOM_PARAMETER_SAVE_DEBOUNCE = 1000;

export enum PredefinedDealCustomFields {
    dealDisqualificationReason = 'deal_disqualification_reason',
}
