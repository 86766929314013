import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ZIndex } from 'src/resources/styles/zIndex';

const bottomCorner = css`
    &:before {
        position: absolute;
        background-color: var(--button-bg-disabled);
        bottom: -10px;
        right: 0%;
        width: 10px;
        height: 10px;
        content: '';
    }
    &:after {
        position: absolute;
        background-color: var(--button-gray-bg);
        bottom: -10px;
        right: 0%;
        width: 10px;
        height: 10px;
        content: '';
        border-top-right-radius: 15px;
    }
`;

export const StyledBottomCorner = styled.div`
    width: 64px;
    height: 64px;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    z-index: ${ZIndex.z1};
    ${bottomCorner}
`;

export const StyledItemShadow = styled.div<{
    isVisible: boolean;
    hasAnimation: boolean;
}>`
    position: absolute;
    top: -20px;
    height: 74px;
    background: transparent;
    width: 20px;
    right: -20px;
    z-index: ${ZIndex.z1};
    ${({ hasAnimation }) => hasAnimation && 'transition: opacity 0.3s'};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    box-shadow:
        0px 16px 32px 0px rgb(221 225 230),
        0px 12px 24px 0px rgb(221 225 230);
`;
