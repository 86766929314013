import styled from '@emotion/styled';

export const ArrowStyled = styled.div<{
    isVisible: boolean;
    hasAnimation: boolean;
}>`
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-right: 13px solid var(--button-bg-disabled);
    border-bottom: 14px solid transparent;
    position: absolute;
    ${({ hasAnimation }) => hasAnimation && 'transition: left 0.3s;'};
    left: ${({ isVisible }) => (isVisible ? '80%' : '100%')};
`;
