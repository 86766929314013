import {
    LinkedinProfileFragment,
    LinkedinSearchMetaFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';

export interface DiscoveredContactProfile {
    title: string;
    location: string;
    linkedinUsername: string;
    headline: string;
    hasPhone: boolean;
    hasEmail: boolean;
    fullName: string;
    companyCountry: string;
    companyName: string;
}

export interface DiscoveredContactProfileDetail {
    personalEmail: string[];
    workEmail: string[];
    phone: string[];
}

export interface SearchMetadata {
    page: number;
    pageSize: number;
    total: number;
    usedCompanyName: string;
}

export interface ContactDiscoveryResults {
    contacts: DiscoveredContactProfile[];
    metadata: SearchMetadata;
}

export const mapRawResponseToContactDiscoveryResults = (
    rawContacts: LinkedinProfileFragment[],
    metadata: LinkedinSearchMetaFragment,
    companyName: string,
): ContactDiscoveryResults => {
    return {
        contacts: rawContacts,
        metadata: {
            ...metadata,
            usedCompanyName: companyName,
        },
    };
};
