import styled from '@emotion/styled';
import { Divider } from '@mui/material';

export const ItemRow = styled.div`
    display: flex;
    align-items: center;
    padding: 4px 12px;
    cursor: pointer;
    &:hover {
        color: var(--accent-link);
        svg path {
            fill: var(--accent-link);
        }
    }
    svg path {
        fill: var(--main-font);
    }
`;

export const ItemRowText = styled.span`
    margin-left: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
`;

export const ItemRowDivider = styled(Divider)`
    margin: 4px 0 !important;
    background-color: var(--button-gray-bg) !important;
    height: 2px !important;
`;
