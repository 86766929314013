/**
 * Enum with all available feature flags.
 */
export enum FeatureFlag {
    placeholderFlag = 'placeholderFlag',
    neuralSearch = 'neural-search-fe',
    byopManualFlow = 'byop-manual-flow',
    autoCompanyEnrichment = 'auto-company-enrichment',
    contactFinder = 'contact-finder',
}

/**
 * Type for a feature flag value.
 */
export type FeatureFlagValue =
    | string
    | number
    | boolean
    | {
          [key: string]: string | number | boolean;
      };

export interface NonAnonymousUserContext {
    isAnonymous: false;
    user_id: string;
    email: string;
    customData: {
        company_bid: number;
    };
}
