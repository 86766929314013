import { AbortParams } from 'src/data/api/api-client';
import { ProcessMultipartGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

interface RequestImportParams extends AbortParams {
    targetPipelineId: string;
    externalSourceUrl?: string;
    externalSourceUpload?: File;
}

export interface IBringYourOwnProjectsApi {
    requestDealImport: (payload: RequestImportParams) => Promise<boolean>;
}

export const createBringYourOwnProjectsApi = (
    processMultipartGqlSdk: ProcessMultipartGqlSdk,
): IBringYourOwnProjectsApi => {
    const requestDealImport = async (
        params: RequestImportParams,
    ): Promise<boolean> => {
        const response = await processMultipartGqlSdk.RequestDealImport(
            {
                targetPipelineId: params.targetPipelineId,
                externalSourceUrl: params.externalSourceUrl,
                externalSourceUpload: params.externalSourceUpload,
            },
            { signal: params.signal },
        );
        return response.requestDealImport.ok;
    };

    return {
        requestDealImport,
    };
};
