import { ExportToCRMApi } from 'src/data/api/export-to-crm/export-to-crm.api';
import { ExportService } from 'src/data/services/export/export.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { AssociatedCompaniesStore } from 'src/data/stores/associated-companies/associated-companies.store';
import { CustomFieldsStore } from 'src/data/stores/custom-fields/custom-fields.store';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { ExportStore } from 'src/data/stores/export/export.store';
import { ProcurementTextsStore } from 'src/data/stores/procurement-texts/procurement-texts.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { SubscriptionsStore } from 'src/data/stores/subscriptions/subscriptions.store';
import { UserStore } from 'src/data/stores/user/user.store';
import { TranslationFn } from 'src/domain/features/translation/translation.feature';
import { createAssociatedSearch } from 'src/domain/models/associated-searches/associated-searches.model';
import {
    CrmState,
    Integration,
    IntegrationType,
} from 'src/domain/models/integration/integration.model';
import { Lead } from 'src/domain/models/lead/lead.model';
import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    emptyCancellable,
    handleRequest,
} from 'src/utils/handle-request.utils';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

export interface ExportFeature {
    exportAsPDF: (lead: Lead, t: TranslationFn) => void;
    crmIntegrations: Integration[];
    requestIntegrations: () => Cancellable;
    needToRequestIntegrations: boolean;
    areIntegrationsLoading: boolean;
    selectedIntegration: Integration | null;
    setSelectedIntegration: (integration: Integration) => void;
    exportDealToCrm: (dealId: string) => Cancellable;
    requestCrmState: (dealId: string) => Cancellable;
    needToRequestCrmState: boolean;
    crmState: CrmState | null;
    isCrmStateLoading: boolean;
}

export class ExportFeatureImpl implements ExportFeature {
    get crmIntegrations(): Integration[] {
        return this.exportStore.crmIntegrations;
    }

    get selectedIntegration(): Integration | null {
        return this.exportStore.selectedIntegration;
    }

    get needToRequestIntegrations(): boolean {
        return this.exportStore.needToRequestIntegrations;
    }

    get areIntegrationsLoading(): boolean {
        return this.exportStore.areIntegrationsLoading;
    }

    get needToRequestCrmState(): boolean {
        return this.exportStore.needToRequestCrmState;
    }

    get crmState(): CrmState | null {
        return this.exportStore.crmState;
    }

    get isCrmStateLoading(): boolean {
        return this.exportStore.isCrmStateLoading;
    }

    constructor(
        private exportToCRMApi: ExportToCRMApi,
        private userStore: UserStore,
        private subscriptionsStore: SubscriptionsStore,
        private associatedCompaniesStore: AssociatedCompaniesStore,
        private procurementTextsStore: ProcurementTextsStore,
        private customFieldsStore: CustomFieldsStore,
        private exportService: ExportService,
        private exportStore: ExportStore,
        private dealsStore: DealsStore,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
    ) {}

    setSelectedIntegration = (integration: Integration) => {
        this.exportStore.setSelectedIntegration(integration);
    };

    getAssociatedSearches = (lead: Lead) => {
        return lead.searchIds
            .map((searchId) => {
                const subscription =
                    this.subscriptionsStore.subscriptions.get(searchId);
                return subscription
                    ? createAssociatedSearch({
                          item_id: subscription.id,
                          name: subscription.name,
                      })
                    : undefined;
            })
            .filter(isNonNullable);
    };

    exportAsPDF = (lead: Lead, t: TranslationFn) => {
        const user = this.userStore.user;
        if (!user) {
            return;
        }

        this.exportService.downloadPDF(
            lead,
            this.procurementTextsStore.procurementTexts.slice(),
            this.associatedCompaniesStore.associatedCompanies,
            this.customFieldsStore.titleMap.get(lead.id),
            user,
            t,
        );
    };

    requestIntegrations = () => {
        return handleRequest(
            this.exportToCRMApi.requestIntegrations,
            {},
            this.exportStore.setIntegrations,
            this.exportStore.setLoadingIntegrations,
            (error) => {
                this.baseStore.onRequestFailed('request-integrations', error);
                this.exportStore.setNeedToRequestIntegrations(false);
            },
        );
    };

    exportDealToCrm = (dealId: string) => {
        if (
            this.selectedIntegration &&
            this.selectedIntegration.integrationType !==
                IntegrationType.DummyCrm
        ) {
            return handleRequest(
                this.exportToCRMApi.exportDealToCrm,
                { dealId, integrationId: this.selectedIntegration.id },
                (flag) => {
                    this.exportStore.setNeedToRequestCrmState(flag);
                    const deal = this.dealsStore.dealsMap.get(dealId);
                    if (deal) {
                        this.mixpanelService.trackEvent(
                            MixpanelEventName.ExportedToCrm,
                            {
                                dealId,
                                pipelineId: deal.pipeline.id,
                                pipelineName: deal.pipeline.name,
                            },
                            dealId,
                        );
                    }
                },
                doNothing,
                (error) =>
                    this.baseStore.onRequestFailed('export-deal-to-crm', error),
            );
        }
        return emptyCancellable;
    };

    requestCrmState = (dealId: string) => {
        if (this.selectedIntegration) {
            const { id, integrationType } = this.selectedIntegration;
            if (integrationType !== IntegrationType.DummyCrm) {
                return handleRequest(
                    this.exportToCRMApi.requestCrmState,
                    { dealId, integrationId: id },
                    this.exportStore.setCrmState,
                    this.exportStore.setLoadingCrmState,
                    (error) => {
                        this.baseStore.onRequestFailed(
                            'request-crm-state',
                            error,
                        );
                        this.exportStore.setNeedToRequestCrmState(false);
                    },
                );
            }
        }

        return emptyCancellable;
    };
}
