import isEqual from 'lodash/isEqual';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { getI18n } from 'react-i18next';

import {
    SortingOperator,
    SortingOrder,
    UserSettingName,
} from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { UsersApi } from 'src/data/api/user/user.api';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import {
    PerformanceViewVisualizationLayout,
    RefetchPipelineViewDataState,
    ViewPreferenceValueType,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import {
    isSortingSettingArray,
    SettingsValueType,
    SortingSetting,
    userSettingsMap,
} from 'src/domain/models/settings/settings.model';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { PreferenceDefaultValue, buildDefaultPreferenceValues } from './utils';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { SortingKeys } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';

export interface IViewPreferencesFeature {
    assigneePreferences: string[];
    showPausedDealsPreference: boolean;
    customParameterTagPreferences: string[];
    visualizationMode: PerformanceViewVisualizationLayout;
    paginationRowsCount: number;
    sortingPreferences: SortingSetting[];
    selectedStageIds: string[];
    thereAreOverwrittenPreferences: boolean;
    refetchPipelineViewDataState: RefetchPipelineViewDataState;
    savingPreferences: boolean;
    additionalActivePreferencesCount: number;
    preferencesInitialised: boolean;
    initializeSavedPreferences: () => void;
    savePreference: (
        setting: UserSettingName,
        value: SettingsValueType,
    ) => void;
    resetPreferences: () => Promise<void>;
}

const dealTableSortingKeysToReadableName: Partial<Record<SortingKeys, string>> =
    {
        [SortingKeys.SkDealTitle]: 'Deal name',
        [SortingKeys.SkDealCurrentStageId]: 'Stage',
        [SortingKeys.SkDealOutreachCount]: 'Outreach activity',
        [SortingKeys.SkDealClosestNextStepDate]: 'Next step',
        [SortingKeys.SkDealStageUpdatedDate]: 'Last moved',
    } as const;

const sortDirectionToTrackingName: Record<SortingOperator, SortingOrder> = {
    [SortingOperator.SopAsc]: SortingOrder.Asc,
    [SortingOperator.SopDesc]: SortingOrder.Desc,
};

export const ALL_AVATAR_OPTION_ID = 'ALL-SELECTED';

export function createViewPreferencesFeature(
    viewPreferencesStore: IViewPreferencesStore,
    userStore: UserStore,
    userApi: UsersApi,
    accountConfigurationStore: IAccountConfigurationStore,
    pipelineStore: PipelineStore,
    mixpanelService: MixpanelService,
    baseStore: IBaseStore,
): IViewPreferencesFeature {
    const feature = {
        t: getI18n().t,
        savingPreferences: false,
        preferencesInitialised: false,

        getDefaultValueByPreferenceName: (
            preferenceName: UserSettingName,
        ): SettingsValueType | undefined => {
            const defaultValues = buildDefaultPreferenceValues(
                accountConfigurationStore.customParameterTag,
            );

            const defaultValue = defaultValues.find(
                (preference) => preference.name === preferenceName,
            );
            return defaultValue?.value;
        },

        get assigneePreferences(): string[] {
            const assignees = viewPreferencesStore.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesAssignees,
            ) as number[];

            const assigneesCount = assignees.length;
            return assigneesCount
                ? assignees.map((id) => id.toString())
                : [ALL_AVATAR_OPTION_ID];
        },

        get showPausedDealsPreference() {
            return viewPreferencesStore.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesShowPausedDeals,
            ) as boolean;
        },

        get customParameterTagPreferences(): string[] {
            const customParameterTag =
                accountConfigurationStore.customParameterTag;
            if (customParameterTag) {
                return (
                    viewPreferencesStore.getPreferenceBySettingName(
                        UserSettingName.PipelineViewPreferencesCustomParameterTag,
                    ) as Record<string, string[]>
                )[customParameterTag.name];
            }
            return [];
        },

        get visualizationMode(): PerformanceViewVisualizationLayout {
            return viewPreferencesStore.visualizationModePreference;
        },

        get paginationRowsCount() {
            return viewPreferencesStore.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesPaginationRows,
            ) as number;
        },

        get sortingPreferences() {
            return viewPreferencesStore.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesSorting,
            ) as SortingSetting[];
        },

        get selectedStageIds() {
            const ids = viewPreferencesStore.getPreferenceBySettingName(
                UserSettingName.PipelineViewPreferencesStageIds,
            ) as string[];
            return ids;
        },

        get thereAreOverwrittenPreferences(): boolean {
            const overwritten = feature.getOverwrittenPreferences();
            return overwritten.length > 0;
        },

        get additionalActivePreferencesCount(): number {
            const additionalPreferences =
                viewPreferencesStore.preferences.filter(
                    ({ setting }) =>
                        setting ===
                            UserSettingName.PipelineViewPreferencesShowPausedDeals ||
                        setting ===
                            UserSettingName.PipelineViewPreferencesCustomParameterTag ||
                        (setting ===
                            UserSettingName.PipelineViewPreferencesStageIds &&
                            feature.visualizationMode ===
                                PerformanceViewVisualizationLayout.table),
                );

            const preferencesChanged = additionalPreferences.filter(
                (preference) => {
                    const defaultValue =
                        feature.getDefaultValueByPreferenceName(
                            preference.setting,
                        );

                    return feature.hasPreferenceChanged(
                        preference.setting,
                        defaultValue,
                        preference.value,
                    );
                },
            );

            return preferencesChanged.length;
        },

        get refetchPipelineViewDataState() {
            return viewPreferencesStore.refetchPipelineViewDataState;
        },

        initializeSavedPreferences: () => {
            const {
                pipelineViewPreferencesAssignees,
                pipelineViewPreferencesShowPausedDeals,
                pipelineViewPreferencesCustomParameterTag,
                pipelineViewPreferencesVisualizationMode,
                pipelineViewPreferencesPaginationRows,
                pipelineViewPreferencesSorting,
                pipelineViewPreferencesStageIds,
            } = userStore.user?.settings || {};

            viewPreferencesStore.setPreference(
                UserSettingName.PipelineViewPreferencesAssignees,
                pipelineViewPreferencesAssignees ??
                    feature.getDefaultValueByPreferenceName(
                        UserSettingName.PipelineViewPreferencesAssignees,
                    )!,
            );

            viewPreferencesStore.setPreference(
                UserSettingName.PipelineViewPreferencesShowPausedDeals,
                pipelineViewPreferencesShowPausedDeals ??
                    feature.getDefaultValueByPreferenceName(
                        UserSettingName.PipelineViewPreferencesShowPausedDeals,
                    )!,
            );

            if (accountConfigurationStore.customParameterTag) {
                viewPreferencesStore.setPreference(
                    UserSettingName.PipelineViewPreferencesCustomParameterTag,
                    pipelineViewPreferencesCustomParameterTag ??
                        feature.getDefaultValueByPreferenceName(
                            UserSettingName.PipelineViewPreferencesCustomParameterTag,
                        )!,
                );
            }

            viewPreferencesStore.setPreference(
                UserSettingName.PipelineViewPreferencesVisualizationMode,
                pipelineViewPreferencesVisualizationMode ??
                    feature.getDefaultValueByPreferenceName(
                        UserSettingName.PipelineViewPreferencesVisualizationMode,
                    )!,
            );

            viewPreferencesStore.setPreference(
                UserSettingName.PipelineViewPreferencesPaginationRows,
                pipelineViewPreferencesPaginationRows ??
                    feature.getDefaultValueByPreferenceName(
                        UserSettingName.PipelineViewPreferencesPaginationRows,
                    )!,
            );

            viewPreferencesStore.setPreference(
                UserSettingName.PipelineViewPreferencesSorting,
                pipelineViewPreferencesSorting ??
                    feature.getDefaultValueByPreferenceName(
                        UserSettingName.PipelineViewPreferencesSorting,
                    )!,
            );

            viewPreferencesStore.setPreference(
                UserSettingName.PipelineViewPreferencesStageIds,
                (pipelineViewPreferencesStageIds ?? []).length > 0
                    ? pipelineViewPreferencesStageIds!
                    : feature.getDefaultValueByPreferenceName(
                          UserSettingName.PipelineViewPreferencesStageIds,
                      )!,
            );

            feature.preferencesInitialised = true;
        },

        onPreferenceSaved: (
            setting: UserSettingName,
            value: SettingsValueType,
        ) => {
            viewPreferencesStore.setPreference(setting, value);
            feature.updateUserSettings([{ setting, value }]);
            feature.trackDealTableSortingUsage(setting, value);
        },

        trackDealTableSortingUsage: (
            setting: UserSettingName,
            value: SettingsValueType,
        ) => {
            if (setting !== UserSettingName.PipelineViewPreferencesSorting) {
                return;
            }

            if (!isSortingSettingArray(value)) {
                return;
            }

            const sortingValue = value as SortingSetting[];

            mixpanelService.trackEvent(
                MixpanelEventName.ClickedToSortDealTable,
                {
                    columnName:
                        dealTableSortingKeysToReadableName[
                            sortingValue[0].field
                        ],
                    sortingDirection:
                        sortDirectionToTrackingName[sortingValue[0].sop],
                },
            );
        },

        onSavingPreference: (saving: boolean) => {
            feature.savingPreferences = saving;
        },

        savePreference: async (
            setting: UserSettingName,
            value: SettingsValueType,
        ) => {
            try {
                const success = await handleRequestAsync(
                    userApi.changeUserSetting,
                    { setting, value },
                    feature.onSavingPreference,
                );

                if (success) {
                    feature.onPreferenceSaved(setting, value);
                }
            } catch (error) {
                baseStore.onRequestFailed(
                    'save-pipeline-view-preference',
                    error as Error,
                );
            }
        },

        resetPreferences: async () => {
            try {
                runInAction(() => {
                    feature.savingPreferences = true;
                });

                const overwrittenPreferences =
                    feature.getOverwrittenPreferences();

                const defaultValues = buildDefaultPreferenceValues(
                    accountConfigurationStore.customParameterTag,
                );

                const requests = overwrittenPreferences.map(({ setting }) =>
                    userApi.changeUserSetting({
                        setting,
                        value: defaultValues.find(
                            ({ name }) => name === setting,
                        )!.value,
                        signal: new AbortController().signal,
                    }),
                );

                await Promise.all(requests);

                runInAction(() => {
                    feature.onResetPreferences(
                        overwrittenPreferences,
                        defaultValues,
                    );
                    feature.savingPreferences = false;
                });
            } catch (error) {
                baseStore.onRequestFailed(
                    'reset-pipeline-view-preferences',
                    error as Error,
                );
            }
        },

        onResetPreferences: (
            overwrittenPreferences: ViewPreferenceValueType[],
            defaultValues: PreferenceDefaultValue[],
        ) => {
            const preferencesRestored = overwrittenPreferences.map(
                (preference) => ({
                    ...preference,
                    value: defaultValues.find(
                        ({ name }) => name === preference.setting,
                    )!.value,
                }),
            );

            preferencesRestored.forEach(({ setting, value }) =>
                viewPreferencesStore.setPreference(setting, value),
            );
            feature.updateUserSettings(preferencesRestored);
        },

        updateUserSettings: (savedPreferences: ViewPreferenceValueType[]) => {
            const currentUser = userStore.user;

            if (currentUser) {
                savedPreferences.forEach((preference) => {
                    currentUser.settings = {
                        ...currentUser.settings,
                        [userSettingsMap[preference.setting]]: preference.value,
                    };
                });
            }
        },

        getOverwrittenPreferences: () => {
            const defaultValues = buildDefaultPreferenceValues(
                accountConfigurationStore.customParameterTag,
            );

            const overwritten = viewPreferencesStore.preferences.filter(
                (preference) => {
                    const settingName = userSettingsMap[preference.setting];
                    const savedPreference =
                        userStore.user?.settings[settingName];
                    const defaultValue = defaultValues.find(
                        ({ name }) => name === preference.setting,
                    )?.value;

                    return feature.hasPreferenceChanged(
                        preference.setting,
                        defaultValue,
                        savedPreference,
                    );
                },
            );

            return overwritten;
        },

        /**
         * Helper function which compares preferences values.
         * @param settingName The setting name associated with the preference
         * @param baseValue The base value for comparison
         * @param value The value to be compared
         * @returns Returns true case the preference value has changed, false otherwise
         */
        hasPreferenceChanged: (
            settingName: UserSettingName,
            baseValue?: SettingsValueType | null,
            value?: SettingsValueType | null,
        ) => {
            let first = baseValue;
            let second = value;

            if (
                baseValue === null ||
                baseValue === undefined ||
                value === null ||
                value === undefined
            ) {
                return false;
            }

            if (
                settingName ===
                UserSettingName.PipelineViewPreferencesCustomParameterTag
            ) {
                const customParameterTag =
                    accountConfigurationStore.customParameterTag;
                first = (baseValue as Record<string, string[]>)?.[
                    customParameterTag?.name ?? ''
                ]
                    ?.slice()
                    .sort();
                second = (value as Record<string, string[]>)?.[
                    customParameterTag?.name ?? ''
                ]
                    ?.slice()
                    .sort();
            }

            return !isEqual(first, second);
        },
    };

    makeAutoObservable(feature);

    // Set up reactions
    reaction(
        () => pipelineStore.currentSelectedPipeline,
        (_current, previous) => {
            runInAction(() => {
                const newStageIds = pipelineStore
                    .getPipelineStages()
                    .stages.map(({ id }) => id);

                if (
                    (feature.selectedStageIds &&
                        !feature.selectedStageIds.length) ||
                    !previous
                ) {
                    return;
                }

                const atLeastOneStateStillExists = newStageIds.some((id) =>
                    feature.selectedStageIds.includes(id),
                );

                if (!atLeastOneStateStillExists) {
                    feature.savePreference(
                        UserSettingName.PipelineViewPreferencesStageIds,
                        [],
                    );
                }
            });
        },
    );

    return feature;
}
