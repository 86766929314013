import { noop } from 'lodash';
import { AbortParams } from 'src/data/api/api-client';
import { UserAuthenticationStatus } from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import {
    ProcessGqlSdk,
    UserGqlSdk,
} from 'src/data/api/graphql/graphql-client.wrapper';
import { UserAuthResponse } from 'src/data/api/user/user.api';
import {
    mapAccountType,
    mapLicenseType,
} from 'src/domain/models/company/company.model';
import { createUser } from 'src/domain/models/user/user.model';
import { getCookie } from 'src/utils/cookie.utils';

interface SignInParams extends AbortParams {
    email: string;
    password: string;
}

interface ResetPasswordParams extends AbortParams {
    email: string;
}

interface SetNewPasswordParams extends AbortParams {
    password: string;
    token: string;
}

export interface AuthApi {
    signIn: (params: SignInParams) => Promise<UserAuthResponse>;
    resetPassword: (params: ResetPasswordParams) => Promise<boolean>;
    logout: (params: AbortParams) => Promise<boolean>;
    setXSRFToken: (params: AbortParams) => Promise<boolean>;
    setNewPassword: (params: SetNewPasswordParams) => Promise<boolean>;
}

export const createAuthApi = (
    processGqlSdk: ProcessGqlSdk,
    userGqlSdk: UserGqlSdk,
): AuthApi => {
    const abortController = new AbortController();

    const setXSRFToken = async () => {
        const { setXsrfToken } = await userGqlSdk.SetXSRFToken(undefined, {
            signal: abortController.signal,
        });
        return setXsrfToken.ok;
    };
    if (!getCookie('XSRF-TOKEN')) {
        setXSRFToken();
    }

    const signIn = async ({
        email,
        password,
        signal,
    }: SignInParams): Promise<UserAuthResponse> => {
        const userResponse = await userGqlSdk.Login(
            { email, password },
            { signal },
        );
        const { status, user } = userResponse.loginWithStatus;
        if (status === UserAuthenticationStatus.Success && user) {
            let hubspotToken = undefined;
            try {
                const response = await processGqlSdk.GetMyHubSpotToken();
                hubspotToken = response.me.hubspotToken;
            } catch (e) {
                noop(e);
                /* ignore failed HubspotToken Retrieval */
            }
            const companyType = await processGqlSdk.GetCompanyTypes();
            const { accountType, licenseType } = companyType.company;

            return {
                status: UserAuthenticationStatus.Success,
                user: createUser(
                    user,
                    mapAccountType[accountType],
                    mapLicenseType[licenseType],
                ),
                hubspotToken,
            };
        }
        return {
            status: status as
                | UserAuthenticationStatus.UserInactive
                | UserAuthenticationStatus.InvalidCredentials,
        };
    };

    const setNewPassword = async ({
        password,
        token,
        signal,
    }: SetNewPasswordParams) => {
        await setXSRFToken();
        const { updatePasswordFromResetToken } =
            await userGqlSdk.UpdatePasswordFromResetToken(
                { newPassword: password, token },
                { signal },
            );
        return updatePasswordFromResetToken.ok;
    };

    const resetPassword = async ({ email, signal }: ResetPasswordParams) => {
        const { sendPasswordReset } = await userGqlSdk.SendPasswordReset(
            { email },
            { signal },
        );
        return sendPasswordReset.ok;
    };

    const logout = async (params: AbortParams) => {
        const signal = params?.signal;

        return (await userGqlSdk.Logout({}, { signal })).logout.ok;
    };

    return {
        signIn,
        resetPassword,
        logout,
        setNewPassword,
        setXSRFToken,
    };
};
