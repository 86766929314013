import { IContextAccessor } from './context-acessor.service.interface';

export const createContextAccessor = ({
    getDeal,
    getPipeline,
}: IContextAccessor): IContextAccessor => {
    return {
        getDeal: (...params) => getDeal(...params),
        getPipeline: (...params) => getPipeline(...params),
    };
};
