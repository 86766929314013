import { AbortParams } from 'src/data/api/api-client';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';
import {
    createCustomParameter,
    CustomParameter,
} from 'src/app-features/custom-parameter/data/model/custom-parameter.model';

interface GetCustomParametersParams extends AbortParams {
    dealId: string;
}

interface UpdateParameterValueParams extends AbortParams {
    dealId: string;
    name: string;
    value: string;
}

interface BulkUpdateParametersValues extends AbortParams {
    dealIds: string[];
    name: string;
    value: string;
}

export interface CustomParametersApi {
    getParameters: (
        params: GetCustomParametersParams,
    ) => Promise<CustomParameter[]>;
    updateParameterValue: (
        params: UpdateParameterValueParams,
    ) => Promise<boolean>;
    bulkUpdateParametersValue: (
        params: BulkUpdateParametersValues,
    ) => Promise<string[]>;
}

export const createCustomParametersApi = (
    processGqlSdk: ProcessGqlSdk,
): CustomParametersApi => {
    const getParameters = async (
        params: GetCustomParametersParams,
    ): Promise<CustomParameter[]> => {
        const response = await processGqlSdk.GetDealCustomParameters(params);
        const { deal } = response;
        return deal.customFields?.map(createCustomParameter) ?? [];
    };

    const updateParameterValue = async (
        params: UpdateParameterValueParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.UpdateDealCustomParameters({
            dealId: params.dealId,
            update: {
                customValues: [{ name: params.name, value: params.value }],
            },
        });
        return response.updateDealCustomValues.ok ?? false;
    };

    const bulkUpdateParametersValue = async (
        params: BulkUpdateParametersValues,
    ): Promise<string[]> => {
        const { dealIds, name, value } = params;
        const response = await processGqlSdk.BulkUpdateDealCustomParameters({
            dealIds,
            name,
            value,
        });

        return response.bulkUpdateDealCustomValues.updatedDealIds;
    };

    return { getParameters, updateParameterValue, bulkUpdateParametersValue };
};
