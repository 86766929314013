import { makeAutoObservable } from 'mobx';

import { DealsApi } from 'src/data/api/deal/deal.api';
import { IDealBatchOperationsStore } from 'src/data/stores/deal-batch-operations/deal-batch-operations.store';
import { DealsStore } from 'src/data/stores/deals/deals.store';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    Deal,
    DealState,
    getPauseData,
} from 'src/domain/models/deal/deal.model';
import { StageType } from 'src/domain/models/deal-stage/deal-stage.model';
import {
    getPipelineViewDataTypeBasedOnVisualizationMode,
    RefetchPipelineViewDataSource,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { handleRequest } from 'src/utils/handle-request.utils';

interface TargetStageAndStatus {
    stageId: string;
    status: DealState;
}

export interface DealMoveProcessFeature {
    isModalOpened: boolean;
    selectedPipelineId: string;
    selectedStageStatusId: string;
    selectedStageId: string;
    selectedDealIds: string[];
    showPausedDealsWarning: boolean;
    reactivatePausedDealsOnMove: boolean | undefined;
    setSelectedPipelineId: (ids: string) => void;
    setSelectedStageId: (id: string) => void;
    setSelectedStatus: (state: DealState) => void;
    setReactivatePausedDealsOnMove: (value: boolean | undefined) => void;
    closeModal: (oldTableUsage?: boolean) => void;
    openModal: () => void;
    bulkMoveDeals: (individualDealIdBeingMoved?: string) => void;
}

export class DealMoveProcessFeatureImpl implements DealMoveProcessFeature {
    isModalOpened = false;
    selectedPipelineId = '';
    targetStageAndStatus: TargetStageAndStatus = {
        stageId: '',
        status: DealState.inPlay,
    };
    reactivatePausedDealsOnMove: boolean | undefined = undefined;

    get selectedDealIds() {
        return this.dealBatchOperationsStore.selectedDealIds;
    }

    get selectedStageStatusId() {
        if (this.targetStageAndStatus.status !== DealState.inPlay) {
            return this.targetStageAndStatus.status;
        }
        return this.targetStageAndStatus.stageId;
    }

    get selectedStageId() {
        return this.targetStageAndStatus.stageId;
    }

    get showPausedDealsWarning() {
        const selectedPipeline = this.pipelinesStore.getPipeline(
            this.selectedPipelineId,
        );
        if (!selectedPipeline) {
            return false;
        }
        const isLastPipelineStage =
            selectedPipeline.config.stageConfig.stageMap.get(
                this.selectedStageId,
            )?.type === StageType.last;

        return (
            this.targetStageAndStatus.status === DealState.disqualified ||
            this.targetStageAndStatus.status === DealState.done ||
            isLastPipelineStage
        );
    }

    setReactivatePausedDealsOnMove = (value: boolean | undefined) => {
        this.reactivatePausedDealsOnMove = value;
    };

    setSelectedPipelineId = (pipelineId: string) => {
        this.selectedPipelineId = pipelineId;
    };

    setSelectedStageId = (id: string) => {
        this.targetStageAndStatus.stageId = id;
    };

    setSelectedStatus = (status: DealState) => {
        this.targetStageAndStatus.status = status;
    };

    openModal = () => {
        this.isModalOpened = true;
        this.dealBatchOperationsStore.setBatchOperationStatus(false);
        this.dealBatchOperationsStore.initializeBatchOperationSteps();
    };

    closeModal = (oldTableUsage?: boolean) => {
        this.isModalOpened = false;

        if (this.dealBatchOperationsStore.isBatchOperationSuccessful) {
            if (oldTableUsage) {
                this.dealsStore.setNeedToRequestDealsList(true);
            } else {
                this.requestRefetchForPipelineData();
            }
        }
    };

    onDealsUpdated = (
        updatedDealIds: string[],
        individualDealIdBeingMoved?: string,
    ) => {
        this.dealBatchOperationsStore.setBatchOperationStatus(true);

        if (individualDealIdBeingMoved) {
            this.applyDealToNewPipelineAndStage(individualDealIdBeingMoved);
        } else {
            /** Cleaning old deals from dealMap so when opening the deal the app re-fetches
             * the deal from the server with up to date data
             */
            updatedDealIds.forEach((id) => this.dealsStore.dealsMap.delete(id));
        }

        this.dealBatchOperationsStore.goToNextStep();
    };

    applyDealToNewPipelineAndStage = (dealId: string) => {
        const deal = this.dealsStore.dealsMap.get(dealId);
        const pipeline = this.pipelinesStore.getPipeline(
            this.selectedPipelineId,
        );
        if (deal && pipeline) {
            const newDealData: Deal = {
                ...deal,
                pipeline,
                stageId: this.targetStageAndStatus.stageId,
                state: this.targetStageAndStatus.status,
                pauseData: getPauseData(null),
            };
            this.dealsStore.dealsMap.set(dealId, newDealData);
        }
    };

    onUpdateError = (error?: Error) => {
        if (error) {
            this.dealBatchOperationsStore.setBatchOperationStatus(false);
            this.baseStore.onRequestFailed(
                'update-deals-pipeline-and-stage',
                error,
            );
        }
    };

    bulkMoveDeals = (individualDealIdBeingMoved?: string) => {
        handleRequest(
            this.dealsApi.updateDealsPipelineAndStage,
            {
                dealIds: this.selectedDealIds,
                targetPipelineId: this.selectedPipelineId,
                targetStageId: this.targetStageAndStatus.stageId,
                targetDealState: this.targetStageAndStatus.status,
                unpauseDeals: this.reactivatePausedDealsOnMove,
            },
            ({ dealIds }) =>
                this.onDealsUpdated(dealIds, individualDealIdBeingMoved),
            this.dealBatchOperationsStore.setUpdateInProgress,
            this.onUpdateError,
        );
    };

    requestRefetchForPipelineData = () => {
        const pipelineVisualizationMode =
            this.pipelineViewPreferenceStore.visualizationModePreference;

        this.pipelineViewPreferenceStore.setRefetchPipelineViewDataState({
            active: true,
            refetchType: getPipelineViewDataTypeBasedOnVisualizationMode(
                pipelineVisualizationMode,
            ),
            source: RefetchPipelineViewDataSource.bulkMoveDeal,
        });
    };

    constructor(
        private dealsApi: DealsApi,
        private dealsStore: DealsStore,
        private dealBatchOperationsStore: IDealBatchOperationsStore,
        private pipelinesStore: PipelineStore,
        private pipelineViewPreferenceStore: IViewPreferencesStore,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }
}
