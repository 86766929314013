import { observer } from 'mobx-react';
import {
    CSSProperties,
    FC,
    MouseEvent,
    PropsWithChildren,
    useCallback,
} from 'react';

import { isLocalDevelopment } from 'src/utils/is-local-development.utils';

import { StyledA, StyledLink } from './link.styled';

interface LinkProps {
    href: string;
    className?: string;
    style?: CSSProperties;
    target?: string;
    toLegacyApp?: boolean;
    onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
}

const localAngularUrl = 'https://localhost:4200';

export const Link: FC<PropsWithChildren<LinkProps>> = observer(
    ({ children, href, className, style, target, toLegacyApp, onClick }) => {
        const angularUrl = isLocalDevelopment() ? localAngularUrl : '';
        const onClickLegacyLink = useCallback(
            (e: MouseEvent<HTMLAnchorElement>) => {
                onClick?.(e);
                e.stopPropagation();
            },
            [onClick],
        );

        if (toLegacyApp) {
            const url = `${angularUrl}${href}`;
            return (
                <StyledA
                    className={className}
                    style={style}
                    onClick={onClickLegacyLink}
                    href={url}
                    target={target}
                >
                    {children}
                </StyledA>
            );
        }
        return (
            <StyledLink
                className={className}
                style={style}
                onClick={onClick}
                to={href}
                target={target}
            >
                {children}
            </StyledLink>
        );
    },
);
