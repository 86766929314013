import { doNothing } from '@mui/x-date-pickers/internals/utils/utils';
import { makeAutoObservable } from 'mobx';
import { getI18n } from 'react-i18next';

import { IAchievementsApi } from 'src/app-features/achievements/data/api/achievements.api';
import {
    UserAchievementName,
    UserAchievementState,
    ValueMilestoneType,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { LocationService } from 'src/data/services/location/location.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { ToasterStore } from 'src/data/stores/toaster/toaster.store';
import { UserStore } from 'src/data/stores/user/user.store';
import { ActionFeaturesIdentifiers } from 'src/domain/features/account-configuration/helpers/action-feature.helper';
import { getUserFullName, isBrUser } from 'src/domain/models/user/user.model';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { isProductionEnv } from 'src/utils/environment.utils';
import { handleRequest } from 'src/utils/handle-request.utils';

import { IAchievementsFeature } from './achievements.feature.interface';
import {
    AchievementCategoryList,
    AchievementDetail,
    AchievementMap,
    ColleagueAchievements,
    ColleagueAchievementsDetails,
    PipelineAchievements,
} from './model/achievement.model';
import { AchievementService } from './service/achievements.service';
import {
    getAchievementBadgeIcon,
    getSortedCategoryList,
    getToasterText,
} from './utils';

export class AchievementsFeature implements IAchievementsFeature {
    isLoading = false;
    isTeamAchievementsLoading = false;
    isTeamAchievementsModalOpen = false;

    get userAchievements(): AchievementMap | undefined {
        return this.userStore.userAchievements;
    }

    teamAchievements: PipelineAchievements[] = [];

    t = getI18n().t;

    get achievements(): AchievementCategoryList[] {
        return getSortedCategoryList(this.userAchievements ?? {});
    }

    get isAchievementsEnabled() {
        return !!this.userStore.user?.settings.optInAchievements;
    }

    setIsTeamAchievementsModalOpen = (flag: boolean) => {
        this.isTeamAchievementsModalOpen = flag;
        if (flag) {
            this.mixpanelService.trackEvent(
                MixpanelEventName.TeamAchievementsModalOpen,
            );
        }
    };

    onSetAchievementsEnabled = (flag: boolean) => {
        if (this.userStore.user) {
            this.userStore.user.settings.optInAchievements = flag;
        }
        this.mixpanelService.trackEvent(
            flag
                ? MixpanelEventName.AchievementsOptedIn
                : MixpanelEventName.AchievementsOptedOut,
        );
    };

    setAchievementsEnabled = (optIn: boolean) => {
        handleRequest(
            this.achievementsApi.setAchievementsEnabled,
            { optIn },
            () => this.onSetAchievementsEnabled(optIn),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'set-achievements-enabled',
                    error,
                ),
            'set-achievements-enabled',
        );
    };

    setAchievementsLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    onGetAchievements = (achievements: AchievementDetail[]) => {
        const initialized = this.userAchievements !== undefined;
        if (!initialized) {
            this.userStore.setUserAchievements({});
        }
        achievements.forEach((achievement) => {
            const { name } = achievement;
            const currentAchievement = this.userAchievements?.[name];
            achievement.shouldShowConfirmation =
                !!currentAchievement?.shouldShowConfirmation;
            if (this.isAchievementActive(name)) {
                this.userAchievements![name] = achievement;
                if (
                    initialized &&
                    currentAchievement?.state !== undefined &&
                    currentAchievement?.state !==
                        UserAchievementState.Achieved &&
                    achievement.state === UserAchievementState.Achieved
                ) {
                    this.showAchievementToast(name);
                }
            }
        });
    };

    getAchievements = () => {
        handleRequest(
            this.achievementsApi.getAchievements,
            {},
            this.onGetAchievements,
            this.setAchievementsLoading,
            (error) =>
                this.baseStore.onRequestFailed('get-achievements', error),
            'get-achievements',
        );
    };

    onUpdateAchievementState = (
        name: UserAchievementName,
        state: UserAchievementState,
    ) => {
        const achievement = this.userAchievements?.[name];
        if (achievement) {
            achievement.state = state;
            achievement.shouldShowConfirmation =
                state === UserAchievementState.Started;

            if (state === UserAchievementState.Achieved) {
                this.showAchievementToast(name);
            }
        }
    };

    updateAchievementState = (
        name: UserAchievementName,
        state: UserAchievementState,
    ) => {
        handleRequest(
            this.achievementsApi.updateAchievementState,
            { name, state },
            () => this.onUpdateAchievementState(name, state),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'update-achievement-state',
                    error,
                ),
            'update-achievement-state',
        );
    };

    resetAchievement = (name: UserAchievementName) => {
        this.updateAchievementState(name, UserAchievementState.NotAchieved);
    };

    confirmAchievement = (name: UserAchievementName) => {
        this.updateAchievementState(name, UserAchievementState.Achieved);
    };

    interceptAchievementEvents = (name: UserAchievementName) => {
        if (!this.isAchievementsEnabled) {
            return;
        }
        switch (name) {
            case UserAchievementName.AskArticleWizard: {
                const achievement = this.userAchievements?.[name];
                if (achievement?.state === UserAchievementState.NotAchieved) {
                    this.updateAchievementState(
                        name,
                        UserAchievementState.Achieved,
                    );
                }
                break;
            }
            default: {
                const achievement = this.userAchievements?.[name];
                if (achievement?.state === UserAchievementState.NotAchieved) {
                    this.getAchievements();
                }
                break;
            }
        }
    };

    resetConfirmations = () => {
        Object.values(UserAchievementName).forEach((name) => {
            const achievement = this.userAchievements?.[name];
            if (achievement) {
                achievement.shouldShowConfirmation = false;
            }
        });
    };

    showAchievementToast = (name: UserAchievementName) => {
        const badgeName = this.t(
            `achievement.badge.${name.toLowerCase()}.title`,
        );
        const achievement = this.userAchievements?.[name];
        if (achievement) {
            this.toasterStore.showMessage({
                message: getToasterText(
                    'achievement.toaster.message',
                    { name: badgeName },
                    this.t,
                ),
                autoClose: 5000,
                secondaryAction: () =>
                    this.locationService.pushToHistory(AppRoute.home),
                secondaryActionText: this.t(
                    'achievement.toaster.show_achievements',
                ),
                primaryActionText: this.t('achievement.toaster.dismiss'),
                primaryAsCloseButton: true,
                secondaryAsCloseButton: true,
                iconNode: getAchievementBadgeIcon(achievement),
            });
        }
    };

    isAchievementActive = (name: UserAchievementName) => {
        switch (name) {
            case UserAchievementName.OutreachByPhone:
                return this.accountConfigurationStore.isActionFeatureEnabled(
                    ActionFeaturesIdentifiers.save_contact_to_phone,
                );
            case UserAchievementName.OutreachByEmail:
                return this.accountConfigurationStore.isActionFeatureEnabled(
                    ActionFeaturesIdentifiers.value_prop_outreach,
                );
            case UserAchievementName.AskArticleWizard:
                return this.accountConfigurationStore.isActionFeatureEnabled(
                    ActionFeaturesIdentifiers.xray,
                );
            case UserAchievementName.AssignContactDealRole:
                return this.accountConfigurationStore.haveContactRoles;
            case UserAchievementName.BookedFirstMeeting:
                return this.pipelineStore.isValueMilestoneSet(
                    ValueMilestoneType.MeetingBooked,
                );
            default:
                return true;
        }
    };

    getMixpanelProps = (name: UserAchievementName) => {
        const achievement = this.userAchievements?.[name];
        if (achievement) {
            const category = this.achievements.find(
                (c) => c.category === achievement.category,
            );
            return {
                badgeName: name,
                badgeGroupName: achievement.category,
                badgeGroupCompletedCount: category?.achievements.filter(
                    (a) => a.state === UserAchievementState.Achieved,
                ).length,
            };
        }
        return undefined;
    };

    setTeamAchievementsLoading = (flag: boolean) => {
        this.isTeamAchievementsLoading = flag;
    };

    onGetTeamAchievements = (achievements: ColleagueAchievementsDetails[]) => {
        const map = new Map<string, PipelineAchievements>();

        achievements.forEach(
            ({
                userId,
                defaultPipelineId,
                optInAchievements,
                achievements,
            }) => {
                const colleague = this.userStore.getColleague(userId);
                if (!colleague || (isProductionEnv && isBrUser(colleague))) {
                    return;
                }

                const colleagueAchievements: ColleagueAchievements = {
                    colleague,
                    defaultPipelineId,
                    optInAchievements,
                    achievements: getSortedCategoryList(
                        achievements,
                        this.isAchievementActive,
                    ),
                };

                const existingPipeline = map.get(defaultPipelineId);
                if (existingPipeline) {
                    existingPipeline.achievements.push(colleagueAchievements);
                } else {
                    const pipeline =
                        this.pipelineStore.getPipeline(defaultPipelineId);
                    if (!pipeline) {
                        return;
                    }
                    map.set(defaultPipelineId, {
                        pipelineName: pipeline.name,
                        pipelineId: defaultPipelineId,
                        achievements: [colleagueAchievements],
                    });
                }
            },
        );

        map.forEach((pipelineAchievements) => {
            pipelineAchievements.achievements.sort((a, b) =>
                getUserFullName(a.colleague).localeCompare(
                    getUserFullName(b.colleague),
                ),
            );
        });

        this.teamAchievements = Array.from(map.values());
    };

    getTeamsAchievements = () => {
        handleRequest(
            this.achievementsApi.getTeamsAchievements,
            {},
            this.onGetTeamAchievements,
            this.setTeamAchievementsLoading,
            (error) =>
                this.baseStore.onRequestFailed('get-team-achievements', error),
            'get-team-achievements',
        );
    };

    constructor(
        private userStore: UserStore,
        private achievementsApi: IAchievementsApi,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
        private achievementService: AchievementService,
        private toasterStore: ToasterStore,
        private locationService: LocationService,
        private accountConfigurationStore: IAccountConfigurationStore,
        private pipelineStore: PipelineStore,
    ) {
        makeAutoObservable(this);
        this.achievementService.registerCallback(
            this.interceptAchievementEvents,
        );
    }
}
