import {
    CreatedFromExtraction,
    DealContactPerson,
    createDealContactCompanyFromFragment,
    createDealContactPersonFromFragment,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { ContactUpdateCustomInputQl } from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

import {
    BatchCreateContactWithCompanyParams,
    CreateDealContactCompanyParams,
    CreateDealContactPersonParams,
    DealContactsResponse,
    DeleteDealContactCompanyParams,
    DeleteDealContactPersonParams,
    GetDealContactsParams,
    IDealContactsApi,
    SendContactWithSMSParams,
    UpdateDealContactCompanyParams,
    UpdateDealContactCompanyRoleParams,
    UpdateDealContactPersonParams,
    UpdateDealContactPersonRoleParams,
} from './deal-contacts.api.types';

export const createDealContactsApi = (
    processGqlSdk: ProcessGqlSdk,
): IDealContactsApi => {
    const getDealContacts = async (
        params: GetDealContactsParams,
    ): Promise<DealContactsResponse> => {
        const response = await processGqlSdk.GetDealContacts(params);
        const { deal } = response;
        const { relevantCompanies, contacts } = deal;
        return {
            dealContactCompanies:
                relevantCompanies?.map(createDealContactCompanyFromFragment) ??
                [],
            dealContactPersons:
                contacts?.map(createDealContactPersonFromFragment) ?? [],
        };
    };

    const createContactPerson = async (
        params: CreateDealContactPersonParams,
    ): Promise<DealContactPerson | undefined> => {
        const { data, dealId } = params;
        const { customFields, customInput, ...rest } = data;
        const calculatedCustomInput: ContactUpdateCustomInputQl = {
            customValues: [
                ...(customInput?.customValues ?? []),
                ...(customFields?.map((field) => ({
                    name: field.name,
                    value: field.value ?? '',
                })) ?? []),
            ],
        };
        const response = await processGqlSdk.CreateContact({
            dealId,
            ...rest,
            customInput: calculatedCustomInput,
        });

        const contact = response.createContact
            ? createDealContactPersonFromFragment(response.createContact)
            : undefined;
        return contact;
    };

    const updateContactPerson = async (
        params: UpdateDealContactPersonParams,
    ) => {
        const { contactId, update } = params;
        const updateObj = { ...update, formType: undefined };
        const request = await processGqlSdk.UpdatePerson({
            contactId,
            update: updateObj,
        });

        return createDealContactPersonFromFragment(request.updateContact);
    };

    const updateContactPersonRole = async (
        params: UpdateDealContactPersonRoleParams,
    ) => {
        const { dealPersonId, role, isKey } = params;
        const response = await processGqlSdk.UpdateContactRole({
            contactId: dealPersonId,
            role,
            isKey,
        });
        return response.updateContact?.id
            ? createDealContactPersonFromFragment(response.updateContact)
            : undefined;
    };

    const deleteContactPerson = async (
        params: DeleteDealContactPersonParams,
    ): Promise<boolean> => {
        const response = await processGqlSdk.DeleteContact({
            dealId: params.dealId,
            contactId: params.contactId,
        });

        return response.deleteContact.ok;
    };
    const createContactCompany = async (
        params: CreateDealContactCompanyParams,
    ) => {
        const { dealId, data } = params;
        const { customFields, name, ...rest } = data;
        const response = await processGqlSdk.CreateCompany({
            dealId,
            name: name ?? '',
            ...rest,
            customInput: {
                customValues:
                    customFields?.map(({ name, value }) => ({
                        name,
                        value: value ?? '',
                    })) ?? [],
            },
        });
        const company = response.createRelevantCompany;
        return createDealContactCompanyFromFragment(company);
    };

    const updateContactCompany = async (
        params: UpdateDealContactCompanyParams,
    ) => {
        const { relevantCompanyId, update } = params;
        const updateObj = { ...update, formType: undefined };

        const request = await processGqlSdk.UpdateRelevantCompany({
            relevantCompanyId,
            update: updateObj,
        });
        return createDealContactCompanyFromFragment(
            request.updateRelevantCompany,
        );
    };

    const updateContactCompanyRole = async (
        params: UpdateDealContactCompanyRoleParams,
    ) => {
        const { dealCompanyId: relevantCompanyId, role, isKey } = params;
        const response = await processGqlSdk.UpdateCompanyRole({
            relevantCompanyId,
            role,
            isKey,
        });
        return response.updateRelevantCompany?.id
            ? createDealContactCompanyFromFragment(
                  response.updateRelevantCompany,
              )
            : undefined;
    };

    const deleteContactCompany = async (
        params: DeleteDealContactCompanyParams,
    ) => {
        const { dealId, dealCompanyId: relevantCompanyId } = params;
        const response = await processGqlSdk.DeleteCompany({
            dealId,
            relevantCompanyId,
        });
        return response.deleteRelevantCompany.ok;
    };

    const batchCreateContactWithCompany = async (
        params: BatchCreateContactWithCompanyParams,
    ): Promise<CreatedFromExtraction> => {
        const { dealId, extractedContacts, extractedCompanies } = params;
        const response =
            await processGqlSdk.CreateContactsFromExtractedContacts({
                dealId,
                extractedContacts,
                extractedCompanies,
            });

        const createdFromExtraction: CreatedFromExtraction = {
            contacts: response.createContactsFromExtractedContacts
                ? response.createContactsFromExtractedContacts.contacts.map(
                      createDealContactPersonFromFragment,
                  )
                : [],
            companies: response.createContactsFromExtractedContacts
                ? response.createContactsFromExtractedContacts.relevantCompanies.map(
                      createDealContactCompanyFromFragment,
                  )
                : [],
        };

        return createdFromExtraction;
    };

    const sendContactWithSMS = async (params: SendContactWithSMSParams) => {
        const { phoneNumber, vcardB64 } = params;
        const response = await processGqlSdk.SendContactToPhoneSms({
            phoneNumber,
            vcardB64,
        });
        return response.executeActionFeatureSaveContactToPhoneSms.ok;
    };

    return {
        getDealContacts,
        createContactPerson,
        updateContactPerson,
        updateContactPersonRole,
        deleteContactPerson,
        createContactCompany,
        updateContactCompany,
        updateContactCompanyRole,
        deleteContactCompany,
        batchCreateContactWithCompany,
        sendContactWithSMS,
    };
};
