import { makeAutoObservable } from 'mobx';

import { PipelineApi } from 'src/data/api/pipeline/pipeline.api';
import { IAccountConfigurationStore } from 'src/data/stores/account-configuration/account-configuration.store.interface';
import { PipelineStore } from 'src/data/stores/pipeline/pipeline.store';
import { IPipelineDynamicFiltersStore } from 'src/data/stores/pipeline-data/dynamic-filters/pipeline-dynamic-filters.store.interface';
import { IViewPreferencesStore } from 'src/data/stores/pipeline-data/view-preferences/view-preferences.store.interface';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import {
    DynamicFilter,
    DynamicFilterMetric,
    DynamicFilterToTrackingName,
    orderedDynamicFilters,
} from 'src/domain/models/dynamic-filter/dynamic-filter.model';
import {
    getPipelineViewDataTypeBasedOnVisualizationMode,
    RefetchPipelineViewDataSource,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { StageStatistics } from 'src/domain/models/pipeline/pipeline.model';
import {
    handleRequest,
    handleRequestAsync,
} from 'src/utils/handle-request.utils';

import { sortDealsByStageConversion } from './pipeline-data.feature.util';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';

export interface IPipelineDataFeature {
    /**
     * Data used to display the pipeline information in the `Box view` mode
     */
    stageStatistics: Map<string, StageStatistics>;
    isStageStatisticsLoading: boolean;
    selectedDynamicFilters: Set<DynamicFilter>;
    availableDynamicFilters: DynamicFilter[];
    isDynamicFilterActive: boolean;
    isDynamicFiltersLoading: boolean;
    requestStageStatistics: (pipelineId: string, stageIds: string[]) => void;
    getDynamicFilterCount: (filter: DynamicFilter) => number;
    toggleDynamicFilter: (filter: DynamicFilter) => void;
    isDynamicFilterEnabled: (filter: DynamicFilter) => boolean;
    clearDynamicFilters: () => void;
    requestPipelineDynamicFilterMetrics: (pipelineId: string) => Promise<void>;
}

export function createPipelineDataFeature(
    pipelineApi: PipelineApi,
    performanceViewPreferencesStore: IViewPreferencesStore,
    pipelineDynamicFiltersStore: IPipelineDynamicFiltersStore,
    pipelineStore: PipelineStore,
    accountConfigurationStore: IAccountConfigurationStore,
    baseStore: IBaseStore,
    mixpanelService: MixpanelService,
): IPipelineDataFeature {
    const feature = {
        stageStatistics: new Map<string, StageStatistics>(),
        isPipelineStageStatisticsLoading: false,
        dynamicFilterMetrics: [] as DynamicFilterMetric[],

        get isDynamicFilterActive(): boolean {
            return !!feature.selectedDynamicFilters.size;
        },

        get isDynamicFiltersLoading(): boolean {
            return pipelineDynamicFiltersStore.isDynamicFiltersLoading;
        },

        get isStageStatisticsLoading() {
            return (
                feature.isPipelineStageStatisticsLoading ||
                feature.isDynamicFiltersLoading
            );
        },

        get selectedDynamicFilters() {
            return pipelineDynamicFiltersStore.selectedDynamicFilters;
        },

        get availableDynamicFilters() {
            return orderedDynamicFilters.filter(
                (d) => feature.getDynamicFilterCount(d) > 0,
            );
        },

        getDynamicFilterCount: (filter: DynamicFilter) => {
            const filterMetric = feature.dynamicFilterMetrics.find(
                ({ dynamicFilter }) => dynamicFilter === filter,
            );

            return filterMetric?.count ?? 0;
        },

        toggleDynamicFilter: (filter: DynamicFilter) => {
            if (feature.selectedDynamicFilters.has(filter)) {
                feature.selectedDynamicFilters.delete(filter);
                feature.trackFilterToggleOnMixpanel(filter, false);
            } else {
                feature.selectedDynamicFilters.add(filter);
                feature.trackFilterToggleOnMixpanel(filter, true);
            }

            const pipelineVisualizationMode =
                performanceViewPreferencesStore.visualizationModePreference;
            performanceViewPreferencesStore.setRefetchPipelineViewDataState({
                active: true,
                refetchType: getPipelineViewDataTypeBasedOnVisualizationMode(
                    pipelineVisualizationMode,
                ),
                source: RefetchPipelineViewDataSource.dynamicFilters,
            });
        },

        isDynamicFilterEnabled: (filter: DynamicFilter) => {
            return feature.selectedDynamicFilters.has(filter);
        },

        clearDynamicFilters: () => {
            if (feature.selectedDynamicFilters.size) {
                feature.selectedDynamicFilters.forEach((filter) => {
                    feature.trackFilterToggleOnMixpanel(filter, false);
                });

                pipelineDynamicFiltersStore.clearDynamicFilters();

                const pipelineVisualizationMode =
                    performanceViewPreferencesStore.visualizationModePreference;
                performanceViewPreferencesStore.setRefetchPipelineViewDataState(
                    {
                        active: true,
                        refetchType:
                            getPipelineViewDataTypeBasedOnVisualizationMode(
                                pipelineVisualizationMode,
                            ),
                        source: RefetchPipelineViewDataSource.dynamicFilters,
                    },
                );
            }
        },

        updateDynamicFilters: () => {
            pipelineDynamicFiltersStore.setDynamicFilters(
                new Set(
                    [...feature.selectedDynamicFilters].filter(
                        (df) => feature.getDynamicFilterCount(df) > 0,
                    ),
                ),
            );
        },

        setIsStageStatisticsLoading: (flag: boolean) => {
            feature.isPipelineStageStatisticsLoading = flag;
        },

        onRequestStageStatistics: (stageStatistics: StageStatistics[]) => {
            feature.stageStatistics = new Map<string, StageStatistics>();
            if (stageStatistics) {
                stageStatistics.forEach((stageStatistic) => {
                    stageStatistic.inPlayDealsOfStage =
                        stageStatistic.inPlayDealsOfStage.sort(
                            sortDealsByStageConversion,
                        );
                    feature.stageStatistics.set(
                        stageStatistic.id,
                        stageStatistic,
                    );
                });
                performanceViewPreferencesStore.setRefetchPipelineViewDataState(
                    { active: false },
                );
            }
        },

        requestStageStatistics: (pipelineId: string, stageIds: string[]) => {
            const viewPreferences =
                feature.buildCurrentPipelineViewPreferencesParams();

            const pipeline = pipelineStore.getPipeline(pipelineId);

            if (!pipeline) {
                return;
            }

            handleRequest(
                pipelineApi.getPipelineStatistics,
                {
                    pipelineId,
                    pipelineName: pipeline.name,
                    stageIds,
                    viewPreferences,
                    dynamicFilters: Array.from(feature.selectedDynamicFilters),
                    customParameterTag:
                        accountConfigurationStore.customParameterTag,
                },
                feature.onRequestStageStatistics,
                feature.setIsStageStatisticsLoading,
                (error) =>
                    baseStore.onRequestFailed(
                        'request-stage-statistics',
                        error,
                    ),
                'request-stage-statistics',
            );
        },

        requestPipelineDynamicFilterMetrics: async (pipelineId: string) => {
            const viewPreferences =
                feature.buildCurrentPipelineViewPreferencesParams();
            const dynamicFilterMetrics = await handleRequestAsync(
                pipelineApi.getPipelineDynamicFilterMetrics,
                {
                    pipelineId,
                    viewPreferences,
                    customParameterTag:
                        accountConfigurationStore.customParameterTag,
                },
                pipelineDynamicFiltersStore.setIsDynamicFiltersLoading,
                (error) =>
                    baseStore.onRequestFailed('request-dynamic-filters', error),
                'request-dynamic-filters',
            );
            if (dynamicFilterMetrics?.length !== undefined) {
                feature.dynamicFilterMetrics = dynamicFilterMetrics;
                feature.updateDynamicFilters();
            }
        },

        buildCurrentPipelineViewPreferencesParams: () => {
            const customParameterTag =
                accountConfigurationStore.customParameterTag;

            return performanceViewPreferencesStore.getCurrentPipelineViewPreferencesParams(
                customParameterTag?.name,
            );
        },

        trackFilterToggleOnMixpanel: (
            filter: DynamicFilter,
            toggleOn: boolean,
        ) => {
            const event = toggleOn
                ? MixpanelEventName.AppliedDynamicFilter
                : MixpanelEventName.ClearedDynamicFilter;

            mixpanelService.trackEvent(event, {
                dynamicFilterName: DynamicFilterToTrackingName[filter],
            });
        },
    };

    makeAutoObservable(feature);

    return feature;
}
