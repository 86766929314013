import { makeAutoObservable } from 'mobx';

import { Lead } from 'src/domain/models/lead/lead.model';

export interface CustomFieldsStore {
    isLoading: boolean;
    error?: Error;
    titleMap: Map<string, string>;
    setLeadCustomFieldValue: (
        lead: Lead,
        fieldId: string,
        value: string,
    ) => void;
    setLoading: (flag: boolean) => void;
    setError: (error?: Error) => void;
}

export class CustomFieldsStoreImpl implements CustomFieldsStore {
    isLoading = false;
    error: Error | undefined = undefined;
    titleMap = new Map<string, string>();

    setLeadCustomFieldValue = (lead: Lead, fieldId: string, value: string) => {
        if (fieldId === 'title') {
            this.titleMap.set(lead.id, value);
        }
    };

    setLoading = (flag: boolean) => {
        this.isLoading = flag;
    };

    setError = (error?: Error) => {
        this.error = error;
    };

    constructor() {
        makeAutoObservable(this);
    }
}
