import { MenuItemConfiguration } from '@buildingradar/br_component_lib';

import { isNonNullable } from './is-non-nullable.utils';

export const cutText = (text: string, maxLength: number) => {
    return text.length > maxLength
        ? `${text.slice(0, maxLength).trimEnd()}...`
        : text;
};

export const copyValueToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
};

export const titleCase = (str: string): string => {
    return str.replace(/([^\s:\\-])([^\s:\\-]*)/g, (_, $1, $2) => {
        return $1.toUpperCase() + $2.toLowerCase();
    });
};

export const addSpaceAroundControlCharacters = (text: string) => {
    return text.replace(/[\n\t]/g, (character) => {
        return ` ${character} `;
    });
};

export const isNotEmpty = (value?: string | null): value is string => {
    return value !== undefined && value !== null && value !== '';
};

export const mapStringsToOptions = (
    values: string[],
): MenuItemConfiguration[] => {
    return values
        .map((value) => (value ? { value, label: value } : null))
        .filter(isNonNullable);
};

/**
 * Clean up special characters from the text
 * Useful to avoid issues like line-clamping, text-overflow, etc.
 * @param text Text to clean up
 * @returns Sanitized text
 */
export const cleanUpSpecialCharacters = (text: string): string => {
    return text
        .replace(/\u00A0/g, ' ') // &nbsp;
        .replace(/\u00AD/g, '-') // &shy;
        .replace(/\u200B/g, '') // Zero-width space
        .replace(/[\u2013\u2014]/g, '-') // En and Em dashes
        .replace(/\u2009/g, ' ') // Thin space
        .replace(/\u202F/g, ' ') // Narrow no-break space
        .replace(/\u200C/g, '') // Zero-width non-joiner
        .replace(/\u200D/g, ''); // Zero-width joiner
};
