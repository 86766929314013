import { CaptureOptions, CaptureResult, PostHog, Properties } from 'posthog-js';

export interface IPostHogService {
    capture(
        postHogInstance: PostHog,
        eventName: string,
        properties?: Properties | null,
        options?: CaptureOptions,
    ): CaptureResult | undefined;
}

export const createPostHogService = (): IPostHogService => {
    const capture = (
        postHogInstance: PostHog,
        eventName: string,
        properties?: Properties | null,
        options?: CaptureOptions,
    ): CaptureResult | undefined =>
        postHogInstance.capture(eventName, properties, options);

    return {
        capture,
    };
};
