import { lazy, memo, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { DealProviderContainer } from 'src/presentation/modules/providers/deal.provider';
import { PipelineProviderContainer } from 'src/presentation/modules/providers/pipeline.provider';
import { PipelinesProviderContainer } from 'src/presentation/modules/providers/pipelines.provider';
import { UserProviderContainer } from 'src/presentation/modules/providers/user.provider';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { SecondaryNavbarLayout } from 'src/presentation/shared/secondary-navbar-layout/secondary-navbar-layout.component';

import { SessionLayout } from './session-layout.component';
import { ReminderFlyoutContainer } from 'src/app-features/schedule-reminders/presentation/reminder-flyout.container';

const LazyFolderPage = lazy(() =>
    import(
        '../../../app-features/project-leads/presentation/folder-page/folder-page.component'
    ).then((module) => ({
        default: module.FolderPageComponent,
    })),
);

const LazyNeuralSearchPage = lazy(() =>
    import(
        '../../../app-features/neural-search/presentation/neural-search-page/neural-search-page.container'
    ).then((module) => ({
        default: module.NeuralSearchPageContainer,
    })),
);

const LazyProjectLookupPage = lazy(() =>
    import('../project-lookup/project-lookup.container').then((module) => ({
        default: module.ProjectLookupContainer,
    })),
);

const LazyPipelinesPage = lazy(() =>
    import('../pipeline/pipeline-page.component').then((module) => ({
        default: module.PipelinePageComponent,
    })),
);

const LazyDealPage = lazy(() =>
    import('../deal-view/deal-view.container').then((module) => ({
        default: module.DealViewContainer,
    })),
);

const LazyDashboardPage = lazy(() =>
    import('../dashboard-view/dashboard-view.container').then((module) => ({
        default: module.DashboardViewContainer,
    })),
);

const LazyDealsListPage = lazy(() =>
    import('../deals-list-page/deals-list-page.container').then((module) => ({
        default: module.DealsListPageContainer,
    })),
);

const LazySettingsPage = lazy(() =>
    import('../settings/settings.component').then((module) => ({
        default: module.SettingsComponent,
    })),
);

const LazySearchesConfigurationPage = lazy(() =>
    import(
        '../../../app-features/searches-configuration/presentation/searches-configuration.container'
    ).then((module) => ({
        default: module.SearchesConfigurationContainer,
    })),
);

export const SessionComponent = memo(() => {
    return (
        <SessionLayout>
            <Suspense
                fallback={<SecondaryNavbarLayout title="common.loading" />}
            >
                <UserProviderContainer>
                    <PipelinesProviderContainer>
                        <Switch>
                            <Route
                                path={AppRoute.neuralSearch}
                                render={() => <LazyNeuralSearchPage />}
                            />
                            <Route
                                path={AppRoute.home}
                                render={() => <LazyDashboardPage />}
                            />
                            <Route
                                path={AppRoute.empty}
                                exact
                                render={() => <Redirect to={AppRoute.home} />}
                            />
                            <Route
                                path={AppRoute.screening}
                                render={() => <LazyFolderPage />}
                            />
                            <Route
                                path={AppRoute.inbox}
                                render={() => (
                                    <Redirect to={AppRoute.screening} />
                                )}
                            />
                            <Route
                                path={AppRoute.interesting}
                                render={() => <LazyFolderPage />}
                            />
                            <Route
                                path={AppRoute.snoozed}
                                render={() => <LazyFolderPage />}
                            />
                            <Route
                                path={AppRoute.done}
                                render={() => <LazyFolderPage />}
                            />
                            <Route
                                path={AppRoute.exported}
                                render={() => <LazyFolderPage />}
                            />
                            <Route
                                path={AppRoute.notInteresting}
                                render={() => <LazyFolderPage />}
                            />
                            <Route
                                path={[
                                    `${AppRoute.pipelines}/:pipelineId`,
                                    AppRoute.pipelines,
                                ]}
                                render={() => (
                                    <PipelineProviderContainer>
                                        <LazyPipelinesPage />
                                    </PipelineProviderContainer>
                                )}
                            />
                            <Route
                                path={[
                                    `${AppRoute.deals}/:dealId/reminder/:reminderId`,
                                    `${AppRoute.deals}/:dealId`,
                                ]}
                                render={() => (
                                    <DealProviderContainer>
                                        <LazyDealPage />
                                    </DealProviderContainer>
                                )}
                            />
                            <Route
                                path={AppRoute.generalSearch}
                                render={() => <LazyProjectLookupPage />}
                            />
                            <Route
                                path={AppRoute.markedDone}
                                render={() => <LazyDealsListPage />}
                            />
                            <Route
                                path={AppRoute.disqualified}
                                render={() => <LazyDealsListPage />}
                            />
                            <Route
                                path={AppRoute.settings}
                                render={() => <LazySettingsPage />}
                            />
                            <Route
                                path={`${AppRoute.configureNewSearch}`}
                                component={LazySearchesConfigurationPage}
                            />
                            <Route
                                path={`${AppRoute.configureSearches}/:id`}
                                component={LazySearchesConfigurationPage}
                            />
                        </Switch>
                        <ReminderFlyoutContainer />
                    </PipelinesProviderContainer>
                </UserProviderContainer>
            </Suspense>
        </SessionLayout>
    );
});
