import {
    CustomFieldFragment,
    RolesPartFragment,
} from 'src/data/api/graphql/br_process/generated/graphql-sdk';
import {
    createCustomParameterConfig,
    CustomParameterConfig,
} from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { sortByOrder } from 'src/utils/array.utils';

import {
    ActionFeatureConfiguration,
    IActionFeatureConfiguration,
    RawActionFeatureConfiguration,
} from './action-feature-configuration/action-feature-configuration.model';
import {
    ISalesPeriodConfiguration,
    RawSalesPeriodConfiguration,
    SalesPeriodConfiguration,
} from './sales-period-configuration/sales-period-configuration.model';

export interface IAccountConfiguration {
    salesPeriod?: ISalesPeriodConfiguration;
    actionFeatures: IActionFeatureConfiguration[];
    dealCustomFields: CustomParameterConfig[];
    contactCustomFields: CustomParameterConfig[];
    companyCustomFields: CustomParameterConfig[];
    contactRoles: string[];
    companyRoles: string[];
    isEstimatedDealValueVisible?: boolean;
}

export class AccountConfiguration implements IAccountConfiguration {
    salesPeriod?: SalesPeriodConfiguration;
    actionFeatures: ActionFeatureConfiguration[];
    dealCustomFields: CustomParameterConfig[];
    contactCustomFields: CustomParameterConfig[];
    companyCustomFields: CustomParameterConfig[];
    contactRoles: string[];
    companyRoles: string[];
    isEstimatedDealValueVisible?: boolean;

    constructor(
        rawSalesPeriodConfig?: RawSalesPeriodConfiguration | null,
        rawActionFeaturesConfig?: RawActionFeatureConfiguration[] | null,
        rawDealCustomFields?: CustomFieldFragment[] | null,
        rawContactCustomFields?: CustomFieldFragment[] | null,
        rawCompanyCustomFields?: CustomFieldFragment[] | null,
        rawRoles?: RolesPartFragment | null,
        isEstimatedDealValueVisible?: boolean | null,
    ) {
        this.salesPeriod = rawSalesPeriodConfig
            ? new SalesPeriodConfiguration(rawSalesPeriodConfig)
            : undefined;
        this.actionFeatures = (rawActionFeaturesConfig || []).map(
            (raw) => new ActionFeatureConfiguration(raw),
        );
        this.dealCustomFields = sortByOrder(
            (rawDealCustomFields ?? []).map(createCustomParameterConfig),
        );
        this.contactCustomFields = sortByOrder(
            (rawContactCustomFields ?? []).map(createCustomParameterConfig),
        );
        this.companyCustomFields = sortByOrder(
            (rawCompanyCustomFields ?? []).map(createCustomParameterConfig),
        );
        this.contactRoles = (rawRoles?.contacts ?? []).map(({ name }) => name);
        this.companyRoles =
            (rawRoles?.relevantCompanies ?? []).map(({ name }) => name) ?? [];
        this.isEstimatedDealValueVisible = isEstimatedDealValueVisible ?? false;
    }
}
