import { TFunction } from 'react-i18next';

import {
    DealContactBase,
    DealContactCompany,
    DealContactPerson,
    DealContactType,
    PartialDealContactType,
} from 'src/app-features/contact/data/model/deal-contacts.model';
import { AccountType } from 'src/domain/models/company/company.model';
import { Deal, DealState } from 'src/domain/models/deal/deal.model';
import { getFullName, User } from 'src/domain/models/user/user.model';
import { isNotEmpty } from 'src/utils/string.utils';

export const createContactClipboardContent = (
    contact: PartialDealContactType | undefined,
    t: TFunction,
): string => {
    if (!contact) {
        return '';
    }
    const isPerson = contact.type === DealContactType.person;
    return [
        isPerson
            ? getFullName(contact.firstName, contact.lastName)
            : contact.name,
        contact.role ?? t('deal_view.contacts_section.role.no_deal_role'),
        ...(contact.customFields
            ?.filter((field) => isNotEmpty(field.value))
            .map((field) => `${field.value}`) ?? []),
        isPerson
            ? (contact.relevantCompany?.name ?? '')
            : (contact.contacts
                  ?.filter((contact) => isNotEmpty(contact.name))
                  .join(',') ?? ''),
        contact.notes,
    ]
        .filter(isNotEmpty)
        .join(', \n');
};

export const syncContactsChangesInCompanies = (
    persons: DealContactPerson[],
    companies: DealContactCompany[],
) => {
    companies.forEach((company) => {
        company.contactIds = [];
        company.contacts = [];

        const contactsForCompany = persons.filter(
            ({ relevantCompanyId }) => relevantCompanyId === company.id,
        );

        if (contactsForCompany.length) {
            company.contactIds = contactsForCompany.map(({ id }) => id);
            company.contacts = contactsForCompany.map(
                ({ id, firstName, lastName }) => ({
                    id,
                    name: firstName ?? lastName ?? '',
                }),
            );
        }
    });
};

export const syncCompaniesChangesInContacts = (
    companies: DealContactCompany[],
    persons: DealContactPerson[],
) => {
    persons.forEach((contact) => {
        if (!contact.relevantCompanyId) {
            return;
        }

        const contactCompany = companies.find(
            ({ id }) => id === contact.relevantCompanyId,
        );

        if (contactCompany) {
            contact.relevantCompany = {
                id: contactCompany.id,
                name: contactCompany.name,
            };
        }
    });
};

export const hasNoRole = (val: string | undefined | null) => !isNotEmpty(val);

export const sortContact = (
    first: DealContactBase,
    second: DealContactBase,
) => {
    const firstHasRole = !hasNoRole(first.role);
    const secondHasRole = !hasNoRole(second.role);

    if (firstHasRole && secondHasRole) {
        return first.role!.toLowerCase() > second.role!.toLowerCase() ? 1 : -1;
    }
    if (firstHasRole && !secondHasRole) {
        return -1;
    }
    if (!firstHasRole && secondHasRole) {
        return 1;
    }
    return (first.createdDate ?? 0) > (second.createdDate ?? 0) ? -1 : 1;
};

export const CustomParameterLinkClickName: Record<string, string> = {
    mobile: 'Direct call',
    telephone: 'Direct call',
    mail: 'Direct mail',
    url: 'Direct weblink',
    linkedin: 'Direct LinkedIn',
};

export const shouldRunAutoEnrichmentForCompany = (
    user: User | undefined,
    deal: Deal | undefined,
    company: DealContactCompany | undefined,
) => {
    const isProductionAccount =
        user?.companySettings.accountType === AccountType.Production;
    const inPlayDeal = deal?.state === DealState.inPlay;
    const notEnriched = company?.enrichmentOptions === undefined;
    const notArchived = company?.isKey;
    return isProductionAccount && inPlayDeal && notEnriched && notArchived;
};
