export enum DynamicFilter {
    DfFailedSo = 'DF_FAILED_SO',
    DfNoReminderSet = 'DF_NO_REMINDER_SET',
    DfOverdueReminder = 'DF_OVERDUE_REMINDER',
    DfReadyToReactivate = 'DF_READY_TO_REACTIVATE',
    DfSlowMoving = 'DF_SLOW_MOVING',
    DfUnassigned = 'DF_UNASSIGNED',
}

export interface DynamicFilterMetric {
    count: number;
    dynamicFilter: DynamicFilter;
}

export const orderedDynamicFilters: DynamicFilter[] = [
    DynamicFilter.DfUnassigned,
    DynamicFilter.DfFailedSo,
    DynamicFilter.DfOverdueReminder,
    DynamicFilter.DfSlowMoving,
    DynamicFilter.DfReadyToReactivate,
    DynamicFilter.DfNoReminderSet,
];

export const DynamicFilterToTrackingName: Record<DynamicFilter, string> = {
    [DynamicFilter.DfFailedSo]: 'Failed Stage Objective',
    [DynamicFilter.DfNoReminderSet]: 'Has no next step',
    [DynamicFilter.DfOverdueReminder]: 'Next step overdue',
    [DynamicFilter.DfReadyToReactivate]: 'Ready to reactivate',
    [DynamicFilter.DfSlowMoving]: 'Slow moving',
    [DynamicFilter.DfUnassigned]: 'No deal owner',
};
