import { makeAutoObservable } from 'mobx';
import { buildUniqueUserWebSessionId } from 'src/utils/web-session.utils';

export interface IVpoGenerationStore {
    vpoSessionId: string | null;
    buildVpoSessionId: (userId: number) => string;
    clearVpoSessionId: () => void;
}

export class VpoGenerationStore implements IVpoGenerationStore {
    vpoSessionId: string | null = null;

    constructor() {
        makeAutoObservable(this);
    }

    buildVpoSessionId = (userId: number): string => {
        this.vpoSessionId = buildUniqueUserWebSessionId(userId);
        return this.vpoSessionId;
    };

    clearVpoSessionId = (): void => {
        this.vpoSessionId = null;
    };
}
