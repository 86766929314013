import { CompanyEnrichmentOptions } from 'src/app-features/contact/data/model/deal-contacts.model';
import { GoogleEnrichmentResultKey } from 'src/app-features/google-enrichment/data/google-enrichment.model';
import { isNotEmpty } from 'src/utils/string.utils';
export const getValidItemsLength = (
    options: CompanyEnrichmentOptions[],
    field: GoogleEnrichmentResultKey,
) => {
    return options.filter((op) => isNotEmpty(op[field])).length;
};

export const splitContactName = (
    fullName: string,
): { firstName: string; lastName: string } => {
    const trimmedName = fullName.trim();
    const nameParts = trimmedName.split(' ');

    if (nameParts.length === 0) {
        return { firstName: '', lastName: '' };
    }

    if (nameParts.length === 1) {
        return { firstName: nameParts[0], lastName: '' };
    }

    const firstName = nameParts[0];
    const lastName = nameParts.slice(1).join(' ');

    return { firstName, lastName };
};
