import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { Redirect, useParams } from 'react-router-dom';

import { Stage } from 'src/domain/models/deal-stage/deal-stage.model';
import {
    RefetchPipelineViewDataState,
    RefetchPipelineViewDataType,
    RequestDynamicFilterSources,
} from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { Pipeline } from 'src/domain/models/pipeline/pipeline.model';
import { createPipelineRoute } from 'src/presentation/modules/pipeline/pipeline.utils';
import { SecondaryNavbarLayout } from 'src/presentation/shared/secondary-navbar-layout/secondary-navbar-layout.component';
import { withFeatures } from 'src/utils/component.utils';
import { createCtx } from 'src/utils/context.utils';
import { IChildren } from 'src/utils/react.utils';

interface PipelineContext {
    pipeline: Pipeline;
    pipelineStages: Stage[];
    pipelineId: string;
}

const [usePipeline, PipelineProvider] = createCtx<PipelineContext>();
export default usePipeline;

interface PipelineProviderComponentProps extends IChildren {
    arePipelinesLoading: boolean;
    myPipeline: Pipeline | null;
    refetchPipelineViewDataState: RefetchPipelineViewDataState;
    accountConfigurationsInitialized: boolean;
    preferencesInitialised: boolean;
    getPipeline: (pipelineId: string) => Pipeline | null;
    setCurrentSelectedPipeline: (pipeline: Pipeline) => void;
    clearDynamicFilters: () => void;
    initializeSavedPreferences: () => void;
    requestStageStatistics: (pipelineId: string, stageIds: string[]) => void;
    requestPipelineDeals: (pipelineId: string) => Promise<void>;
    requestPipelineDynamicFilterMetrics: (pipelineId: string) => Promise<void>;
    getPipelineStages: () => {
        stageMap: Map<string, Stage> | undefined;
        stages: Stage[];
    };
}

const PipelineProviderComponent: FC<PipelineProviderComponentProps> = observer(
    ({
        arePipelinesLoading,
        myPipeline,
        refetchPipelineViewDataState,
        accountConfigurationsInitialized,
        preferencesInitialised,
        getPipeline,
        setCurrentSelectedPipeline,
        clearDynamicFilters,
        initializeSavedPreferences,
        requestStageStatistics,
        requestPipelineDeals,
        requestPipelineDynamicFilterMetrics,
        getPipelineStages,
        children,
    }) => {
        const { pipelineId } = useParams<{ pipelineId?: string }>();
        const pipeline = getPipeline(pipelineId ?? '');

        useEffect(() => {
            if (pipeline) {
                setCurrentSelectedPipeline(pipeline);
                clearDynamicFilters();
            }
        }, [clearDynamicFilters, pipeline, setCurrentSelectedPipeline]);

        /* Whenever a pipeline is selected, preferences changed, dynamic filter changed, or deals are bulk moved,
         * the `refetchPipelineViewDataState` is set in the proper way to refetch the pipeline data.
         * The refetch will vary depending on the visualization mode (Grid or Table) */
        useEffect(() => {
            (async () => {
                if (refetchPipelineViewDataState.active && pipeline) {
                    if (
                        RequestDynamicFilterSources.includes(
                            refetchPipelineViewDataState.source,
                        )
                    ) {
                        await requestPipelineDynamicFilterMetrics(pipeline.id);
                    }

                    if (
                        refetchPipelineViewDataState.refetchType ===
                        RefetchPipelineViewDataType.pipelineStatistics
                    ) {
                        const { stageIdsInOrder } = pipeline.config.stageConfig;
                        requestStageStatistics(pipeline.id, stageIdsInOrder);
                    } else {
                        requestPipelineDeals(pipeline.id);
                    }
                }
            })();
        }, [
            refetchPipelineViewDataState,
            pipeline,
            requestStageStatistics,
            requestPipelineDeals,
            requestPipelineDynamicFilterMetrics,
        ]);

        useEffect(() => {
            if (accountConfigurationsInitialized && !preferencesInitialised) {
                initializeSavedPreferences();
            }
        }, [
            accountConfigurationsInitialized,
            initializeSavedPreferences,
            preferencesInitialised,
        ]);

        if (arePipelinesLoading) {
            return <SecondaryNavbarLayout title="common.loading" />;
        }

        if (!myPipeline || !preferencesInitialised) {
            return null;
        }

        if (!pipelineId || !pipeline) {
            return <Redirect to={createPipelineRoute(myPipeline.id)} />;
        }

        const { stages } = getPipelineStages();

        return (
            <PipelineProvider
                value={{
                    pipeline,
                    pipelineId: pipeline.id,
                    pipelineStages: stages,
                }}
            >
                {children}
            </PipelineProvider>
        );
    },
);

export const PipelineProviderContainer = withFeatures(
    PipelineProviderComponent,
)((f) => ({
    arePipelinesLoading: f.pipelineFeature.arePipelinesLoading,
    myPipeline: f.pipelineFeature.myPipeline,
    refetchPipelineViewDataState:
        f.viewPreferencesFeature.refetchPipelineViewDataState,
    accountConfigurationsInitialized:
        f.accountConfigurationFeature.accountConfigurationsInitialized,
    preferencesInitialised: f.viewPreferencesFeature.preferencesInitialised,
    getPipeline: f.pipelineFeature.getPipeline,
    setCurrentSelectedPipeline: f.pipelineFeature.setCurrentSelectedPipeline,
    clearDynamicFilters: f.pipelineDataFeature.clearDynamicFilters,
    initializeSavedPreferences:
        f.viewPreferencesFeature.initializeSavedPreferences,
    requestStageStatistics: f.pipelineDataFeature.requestStageStatistics,
    requestPipelineDeals: f.pipelineTableViewFeature.requestPipelineDeals,
    requestPipelineDynamicFilterMetrics:
        f.pipelineDataFeature.requestPipelineDynamicFilterMetrics,
    getPipelineStages: f.pipelineFeature.getPipelineStages,
}));
