import {
    SortingKey,
    SortingOperator,
    UserSettingName,
} from 'src/data/api/graphql/br_user/generated/graphql-sdk';
import { NoneOption } from 'src/data/api/pipeline/pipeline.api.utils';
import { CustomParameterConfig } from 'src/app-features/custom-parameter/data/model/custom-parameter.model';
import { PerformanceViewVisualizationLayout } from 'src/domain/models/performance-view-preferences/performance-view-preferences.model';
import { SettingsValueType } from 'src/domain/models/settings/settings.model';

export interface PreferenceDefaultValue {
    name: string;
    value: SettingsValueType;
}

/**
 * Build the pipeline view preferences default values
 * @param customParameterTag The custom parameter tag associated with the account (if any)
 * @returns An array of default values
 */
export const buildDefaultPreferenceValues = (
    customParameterTag?: CustomParameterConfig,
): PreferenceDefaultValue[] => {
    const preferences: PreferenceDefaultValue[] = [
        {
            name: UserSettingName.PipelineViewPreferencesAssignees,
            value: [],
        },
        {
            name: UserSettingName.PipelineViewPreferencesShowPausedDeals,
            value: false,
        },
        {
            name: UserSettingName.PipelineViewPreferencesVisualizationMode,
            value: PerformanceViewVisualizationLayout.grid,
        },
        {
            name: UserSettingName.PipelineViewPreferencesPaginationRows,
            value: 25,
        },
        {
            name: UserSettingName.PipelineViewPreferencesSorting,
            value: [
                {
                    field: SortingKey.SkDealStageUpdatedDate,
                    sop: SortingOperator.SopDesc,
                },
            ],
        },
        {
            name: UserSettingName.PipelineViewPreferencesStageIds,
            value: [],
        },
    ];

    if (customParameterTag) {
        preferences.push({
            name: UserSettingName.PipelineViewPreferencesCustomParameterTag,
            value: {
                [customParameterTag.name]: [
                    NoneOption,
                    ...(customParameterTag.acceptedValues?.filter(
                        (value) => value,
                    ) ?? []),
                ],
            },
        });
    }

    return preferences;
};
