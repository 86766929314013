import { DatePicker } from '@buildingradar/ui_library/date-picker';
import { Textarea } from '@buildingradar/ui_library/textarea';
import { observer } from 'mobx-react';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReminderFlyoutDataContext } from 'src/app-features/schedule-reminders/domain/reminders-interface.feature';
import { Colleague } from 'src/domain/models/colleague/colleague.model';
import { UserAssignmentDropdown } from 'src/presentation/shared/user-assignment-dropdown/user-assignment-dropdown.component';
import { getTomorrow, isDateOverdue } from 'src/utils/datetime.utils';
import { getRoot } from 'src/utils/element.utils';
import useCurrentUser from 'src/presentation/modules/providers/user.provider';
import { Button } from '@buildingradar/ui_library/button';
import {
    CreateReminderData,
    UpdateReminderData,
} from 'src/domain/models/reminder/reminder.model';
import { ReminderTargetType } from 'src/domain/models/reminder/reminder-target-type';
import { useLocation } from 'react-router-dom';
import {
    SheetDrawer,
    SheetFooter,
    SheetHeader,
    SheetTitle,
    SheetBody,
    SheetClose,
} from '@buildingradar/ui_library/sheet';

interface Props {
    isOpened: boolean;
    dataContext?: ReminderFlyoutDataContext;
    assignToUsers: Colleague[];
    onCloseRequested: () => void;
    onCreateReminderRequested: (
        data: CreateReminderData,
        dealId?: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    onUpdateReminderRequested: (
        reminderId: string,
        data: UpdateReminderData,
        dealId?: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    markAsCompleted: (
        reminderId: string,
        dealId: string,
        onSuccess?: () => void,
    ) => Promise<void>;
    triggerPipelineDataRefresh: () => void;
}

export const ReminderFlyout: FC<Props> = observer(function Component({
    isOpened,
    dataContext,
    assignToUsers,
    onCloseRequested,
    onCreateReminderRequested,
    onUpdateReminderRequested,
    markAsCompleted,
    triggerPipelineDataRefresh,
}) {
    const { t } = useTranslation();
    const { itemId, language } = useCurrentUser();

    const { dealId, reminder, customInitialDescription, launchSource } =
        dataContext ?? {};

    const [description, setDescription] = useState('');

    const [dueDate, setDueDate] = useState<Date>();

    const [currentAssignedTo, setCurrentAssignedTo] = useState(itemId);

    const { pathname } = useLocation();
    const currentLocationWhenOpening = useRef<string>();

    const onClose = useCallback(() => {
        currentLocationWhenOpening.current = undefined;
        onCloseRequested();
    }, [onCloseRequested]);

    useEffect(() => {
        if (isOpened && dealId && reminder && pathname.includes('/reminder')) {
            const newPath = pathname.replace(/\/reminder\/[^/]+/, '');
            window.history.replaceState(null, '', newPath);
        }
    }, [dealId, isOpened, pathname, reminder]);

    useEffect(() => {
        if (isOpened) {
            if (!currentLocationWhenOpening.current) {
                currentLocationWhenOpening.current = pathname;
                return;
            }
            if (pathname !== currentLocationWhenOpening.current) {
                currentLocationWhenOpening.current = undefined;
                onClose();
            }
        }
    }, [isOpened, onClose, pathname]);

    useEffect(() => {
        if (isOpened) {
            setDescription(
                reminder?.description ??
                    customInitialDescription ??
                    t('actions.reminders.note_default_text'),
            );
            setDueDate(reminder?.dueDate ?? getTomorrow());
            setCurrentAssignedTo(reminder?.assigneeId ?? itemId);
        } else {
            setDescription('');
            setDueDate(getTomorrow());
            setCurrentAssignedTo(itemId);
        }
    }, [
        customInitialDescription,
        isOpened,
        itemId,
        reminder?.assigneeId,
        reminder?.description,
        reminder?.dueDate,
        t,
    ]);

    const isEditMode = !!reminder;

    const isOverdue = !!reminder && isDateOverdue(reminder.dueDate);

    const onSubmit = () => {
        if (!dueDate) return;

        if (isEditMode) {
            onUpdateReminderRequested(
                reminder.id ?? '',
                {
                    description: description,
                    dueDate: new Date(dueDate),
                    assigneeId: currentAssignedTo,
                },
                dealId,
                () => {
                    triggerPipelineDataRefresh();
                },
            );
        } else {
            if (!dealId) return;

            onCreateReminderRequested(
                {
                    description: description,
                    dueDate: new Date(dueDate),
                    assigneeId: currentAssignedTo,
                    target: {
                        id: dealId,
                        type: ReminderTargetType.Deal,
                    },
                    launchSource: launchSource ?? 'Not specified',
                },
                dealId,
                () => {
                    triggerPipelineDataRefresh();
                },
            );
        }
        onClose();
    };

    const onCompleteReminder = () => {
        if (!reminder?.id || !reminder.target?.id) return;

        markAsCompleted(reminder.id, reminder.target.id, () => {
            triggerPipelineDataRefresh();
            onClose();
        });
    };
    const onOpenChange = (open: boolean) => {
        if (!open) {
            onClose();
        }
    };

    return (
        <SheetDrawer
            allowOutsideInteraction
            open={isOpened}
            container={getRoot()}
            onOpenChange={onOpenChange}
        >
            <SheetHeader>
                <SheetTitle>
                    {isEditMode
                        ? t('enablement_panel.reminders.title_edit_mode')
                        : t('enablement_panel.reminders.title_create_mode')}
                </SheetTitle>
            </SheetHeader>
            <SheetBody className="hidden-scrollbar gap-4">
                <Textarea
                    label={t('enablement_panel.reminders.note_input_label')}
                    placeholder={t(
                        'enablement_panel.reminders.note_input_placeholder',
                    )}
                    autoFocus
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
                <DatePicker
                    label={t('enablement_panel.reminders.due_date_input_label')}
                    placeholder={t(
                        'enablement_panel.reminders.due_date_input_placeholder',
                    )}
                    value={dueDate}
                    locale={language}
                    onChange={(date) => setDueDate(date)}
                />
                <div className="mt-2">
                    <span className="font-medium">
                        {t('enablement_panel.reminders.assign_to_input_label')}
                    </span>
                    <UserAssignmentDropdown
                        assignToUsers={assignToUsers}
                        currentAssignedTo={currentAssignedTo}
                        onUserSelected={setCurrentAssignedTo}
                    />
                </div>
            </SheetBody>
            <SheetFooter>
                <Button
                    variant={!isOverdue ? 'standard' : 'outline'}
                    onClick={onSubmit}
                    className="w-full"
                >
                    {t(
                        isEditMode
                            ? 'enablement_panel.reminders.update_reminder_button_label'
                            : 'enablement_panel.reminders.set_reminder_button_label',
                    )}
                </Button>
                {isEditMode && (
                    <Button
                        variant={isOverdue ? 'standard' : 'outline'}
                        onClick={onCompleteReminder}
                        className="w-full"
                    >
                        {t('next_steps.complete_action.button_text')}
                    </Button>
                )}
                <SheetClose>
                    <Button variant="outline" className="w-full">
                        {t('common.close')}
                    </Button>
                </SheetClose>
            </SheetFooter>
        </SheetDrawer>
    );
});
