import { makeAutoObservable } from 'mobx';
import { IBringYourOwnProjectsApi } from './api';
import { handleRequestAsync } from 'src/utils/handle-request.utils';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';

export interface RequestImportPayload {
    targetPipelineId: string;
    externalSourceUrl?: string;
    externalSourceUpload?: File;
}

export interface IBringYourOwnProjectsFeature {
    requestDealImport: (payload: RequestImportPayload) => Promise<boolean>;
}

export class BringYourOwnProjectsFeature
    implements IBringYourOwnProjectsFeature
{
    constructor(
        private byopApi: IBringYourOwnProjectsApi,
        private mixpanelService: MixpanelService,
        private baseStore: IBaseStore,
    ) {
        makeAutoObservable(this);
    }

    requestDealImport = async (payload: RequestImportPayload) => {
        try {
            const ok = await handleRequestAsync(
                this.byopApi.requestDealImport,
                {
                    ...payload,
                },
            );

            if (ok) {
                this.mixpanelService.trackEvent(
                    MixpanelEventName.ByopRequestedImport,
                    {
                        pipelineId: payload.targetPipelineId,
                        byURL: payload.externalSourceUrl,
                        byFileUpload: payload.externalSourceUpload?.name,
                    },
                );
            }

            return ok ?? false;
        } catch (error) {
            this.baseStore.onRequestFailed(
                'request-deal-import',
                error as Error,
            );
            return false;
        }
    };
}
