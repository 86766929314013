import { makeAutoObservable } from 'mobx';

import { CustomFieldsApi } from 'src/data/api/custom-fields/custom-fields.api';
import { FolderTag } from 'src/data/api/graphql/br_project/generated/graphql-sdk';
import { LeadsApi } from 'src/data/api/leads/leads.api';
import { MixpanelEventName } from 'src/data/services/mixpanel/mixpanel.model';
import { MixpanelService } from 'src/data/services/mixpanel/mixpanel.service';
import { CustomFieldsStore } from 'src/data/stores/custom-fields/custom-fields.store';
import { LeadsStore } from 'src/data/stores/leads/leads.store';
import { IBaseStore } from 'src/data/stores/shared/base.store.interface';
import { UserStore } from 'src/data/stores/user/user.store';
import { CustomTitle } from 'src/domain/models/custom-title/custom-title.model';
import {
    FolderType,
    FolderTypeToFolderTagSdkMap,
} from 'src/domain/models/folder/folder.model';
import { Lead } from 'src/domain/models/lead/lead.model';
import { Language } from 'src/domain/models/locale/locale.model';
import { SearchTag } from 'src/domain/models/search-tag/search-tag.model';
import { isTender } from 'src/domain/models/tender/tender.model';
import { doNothing } from 'src/utils/function.utils';
import {
    Cancellable,
    emptyCancellable,
    handleRequest,
} from 'src/utils/handle-request.utils';
import { isNonNullable } from 'src/utils/is-non-nullable.utils';

export type LeadFlyoutType = 'newsFeed' | 'referenceDetails';

export type LeadFlyoutOpenSource = 'Project Card' | 'Deal Page';

export interface LeadsListFeature {
    ids: string[];
    leadsMap: Map<string, Lead>;
    isLoading: boolean;
    allItemsLoaded: boolean;
    /**
     * The id of the lead that is currently in context, useful
     * when we need to display a flyout with specific lead details
     */
    contextLeadId: string;
    /**
     * The type of the opened flyout for the lead list page
     */
    openedFlyoutType?: LeadFlyoutType;
    loadNextPage: (
        folder: FolderType,
        currentSearchTag?: SearchTag,
    ) => Cancellable;
    loadFirstPage: (
        folder: FolderType,
        currentSearchTag?: SearchTag,
    ) => Cancellable;
    setLeadFlyoutOpened: (
        type: LeadFlyoutType,
        source: LeadFlyoutOpenSource,
        leadId: string,
        dealId?: string,
    ) => void;
    closeLeadFlyout: () => void;
    clearContextLeadId: () => void;
    getLeadById: (id: string) => void;
    trackFlyoutSimpleEvent: (eventName: MixpanelEventName) => void;
}

export class LeadsListFeatureImpl implements LeadsListFeature {
    static get ItemsByPage() {
        return 20;
    }

    openedFlyoutType?: LeadFlyoutType;
    contextLeadId = '';

    public allItemsLoaded = false;
    public allResubmittedItemsLoaded = false;
    public resubmittedIds: string[] = [];

    public get isLoading(): boolean {
        return this.leadsStore.isLoading;
    }

    public get ids(): string[] {
        return this.leadsStore.ids;
    }

    public get leadsMap(): Map<string, Lead> {
        return this.leadsStore.leads;
    }

    public get leadsList(): Lead[] {
        const { leads } = this.leadsStore;
        return this.ids.map((id) => leads.get(id)).filter(isNonNullable);
    }

    private trackEventForOpeningLeadFlyout = (
        type: LeadFlyoutType,
        source: LeadFlyoutOpenSource,
        leadId?: string,
        dealId?: string,
    ) => {
        if (type === 'referenceDetails') {
            const lead = this.leadsMap.get(leadId ?? '');
            if (!lead) {
                return;
            }

            const isTenderDocument = isTender(lead.mergedDocument);

            const referenceFlyoutEventType = isTenderDocument
                ? MixpanelEventName.ClickedToViewBidDetails
                : MixpanelEventName.ReferenceDetailsOpened;

            this.mixpanelService.trackEvent(
                referenceFlyoutEventType,
                {
                    referenceDetailsSource: source,
                },
                dealId,
            );
        } else {
            this.mixpanelService.trackEvent(
                MixpanelEventName.ClickedToViewNewsFeedPanel,
                {
                    referenceDetailsSource: source,
                },
                dealId,
            );
        }
    };

    setLeadFlyoutOpened = (
        type: LeadFlyoutType,
        source: LeadFlyoutOpenSource,
        leadId: string,
        dealId?: string,
    ) => {
        this.openedFlyoutType = type;
        this.contextLeadId = leadId;

        this.trackEventForOpeningLeadFlyout(type, source, leadId, dealId);
    };

    trackFlyoutSimpleEvent = (eventName: MixpanelEventName) => {
        this.mixpanelService.trackEvent(eventName);
    };

    closeLeadFlyout = () => {
        this.openedFlyoutType = undefined;
    };

    clearContextLeadId = () => {
        this.contextLeadId = '';
    };

    clearEntities = () => {
        this.leadsStore.clear();
    };

    private setLoadingFolderLeads = (flag: boolean) => {
        this.leadsStore.setLoading(flag);
    };

    public setAllItemsLoaded = (flag: boolean) => {
        this.allItemsLoaded = flag;
    };

    public setAllResubmittedItemsLoaded = (flag: boolean) => {
        this.allResubmittedItemsLoaded = flag;
    };

    private saveNewPage = (leads: Lead[]) => {
        leads.forEach((lead) => {
            this.leadsStore.set(lead);
            this.leadsStore.addId(lead.id);
        });

        this.requestLeadsTitles(leads);

        if (
            leads.length === 0 ||
            leads.length < LeadsListFeatureImpl.ItemsByPage
        ) {
            this.setAllItemsLoaded(true);
        }
    };

    constructor(
        private leadsApi: LeadsApi,
        private customFieldsApi: CustomFieldsApi,
        private customFieldsStore: CustomFieldsStore,
        private userStore: UserStore,
        private leadsStore: LeadsStore,
        private baseStore: IBaseStore,
        private mixpanelService: MixpanelService,
    ) {
        makeAutoObservable(this);
    }

    public loadNextPage = (
        folder: FolderType,
        currentSearchTag?: SearchTag,
    ): Cancellable => {
        const lastLeadIndex = this.leadsList.length - 1;
        const lastLead = this.leadsList[lastLeadIndex];
        return this.requestLeadsList(folder, currentSearchTag, lastLead);
    };

    public loadFirstPage = (
        folder: FolderType,
        currentSearchTag?: SearchTag,
    ): Cancellable => {
        this.setAllItemsLoaded(false);
        this.setAllResubmittedItemsLoaded(false);
        this.clearEntities();
        return this.requestLeadsList(folder, currentSearchTag);
    };

    public requestLeadsList = (
        folder: FolderType,
        currentSearchTag?: SearchTag,
        lastLead?: Lead,
    ): Cancellable => {
        if (this.allItemsLoaded) {
            return emptyCancellable;
        }

        // don't load unassigned folder for inbox
        let includeUnassigned = folder !== FolderType.inbox;

        const searchTagsForRequest = currentSearchTag
            ? [currentSearchTag]
            : [...this.userStore.searchTags];

        // if there are only 1 search tag, load unassigned folder only if unassigned is the search tag, else don't load
        if (searchTagsForRequest.length === 1) {
            if (searchTagsForRequest[0] === 'unassigned') {
                searchTagsForRequest.pop();
                includeUnassigned = true;
            } else {
                includeUnassigned = false;
            }
        }

        const lastEntityTime = lastLead
            ? folder === FolderType.inbox
                ? lastLead.updatedInProduct
                : lastLead.folderUpdated
            : new Date();

        const searchTags = searchTagsForRequest.map((tag) => Number(tag));

        return handleRequest(
            this.leadsApi.getLeads,
            {
                folderName: FolderTypeToFolderTagSdkMap[folder] as FolderTag,
                includeUnassigned,
                searchTags,
                count: LeadsListFeatureImpl.ItemsByPage,
                cursorDate: lastEntityTime,
                language: this.userStore.user?.language ?? Language.En,
            },
            this.saveNewPage,
            this.setLoadingFolderLeads,
            (error) =>
                this.baseStore.onRequestFailed('request-leads-list', error),
            'request-leads-list',
        );
    };

    onRequestTitles = (customTitles: CustomTitle[]) => {
        customTitles.forEach((customField) => {
            this.customFieldsStore.titleMap.set(
                customField.leadId.toString(),
                customField.value,
            );
        });
    };

    requestLeadsTitles = (leads: Lead[]) => {
        if (!leads.length) {
            return;
        }

        const projectIds = leads.map((lead) => Number(lead.id));

        handleRequest(
            this.customFieldsApi.getCustomTitlesOfProjects,
            { projectIds },
            (customTitles) => this.onRequestTitles(customTitles),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed('request-leads-title', error),
            'request-leads-title',
        );
    };

    private onSingleLeadSuccess = (lead?: Lead) => {
        if (lead) {
            this.leadsStore.set(lead);
            this.requestTitle(lead);
        }
    };

    getLeadById = (id: string) => {
        handleRequest(
            this.leadsApi.getLeadById,
            {
                projectId: Number(id),
                language: this.userStore.user?.language ?? Language.En,
            },
            this.onSingleLeadSuccess,
            this.leadsStore.setSingleLeadLoading,
            (error) => this.baseStore.onRequestFailed('get-lead-by-id', error),
        );
    };

    private onRequestTitle = (customTitles: CustomTitle[]) => {
        if (customTitles.length) {
            const customTitle = customTitles[0];
            this.customFieldsStore.titleMap.set(
                customTitle.leadId.toString(),
                customTitle.value,
            );
        }
    };

    requestTitle = (lead: Lead) => {
        handleRequest(
            this.customFieldsApi.getCustomTitlesOfProjects,
            { projectIds: [Number(lead.id)] },
            (customFields) => this.onRequestTitle(customFields),
            doNothing,
            (error) =>
                this.baseStore.onRequestFailed(
                    'request-leads-custom-titles',
                    error,
                ),
        );
    };
}
