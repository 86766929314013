import { BrIcon, IconName } from '@buildingradar/br_component_lib';
import { observer } from 'mobx-react';
import { FC, Fragment, ReactElement, useMemo } from 'react';

import { SearchType } from 'src/app-features/searches-configuration/domain/models/configured-search';
import { BuildSearchUrlQueryParam } from 'src/app-features/searches-configuration/presentation/utils/utils';
import { TranslationFn } from 'src/domain/features/translation/translation.feature';
import { User } from 'src/domain/models/user/user.model';
import { AppRoute } from 'src/presentation/modules/router/app-route.list';
import { Link } from 'src/presentation/shared/link/link.component';

import { ItemRow, ItemRowText, ItemRowDivider } from './sub-menu.styled';
import { Tooltip } from '@buildingradar/ui_library/tooltip';
import { MoreHorizontal } from 'lucide-react';

interface SubMenuComponentProps {
    t: TranslationFn;
    user: User;
}

interface RouteOption {
    text: string;
    icon: ReactElement;
    path: AppRoute;
    legacy: false;
    className: string;
    divider?: boolean;
}

export const SubMenuComponent: FC<SubMenuComponentProps> = observer(
    ({ t, user }) => {
        const shouldShowSearches =
            !user.featureToggle.inboxOnly || user.isImpersonated;

        const submenuOptions = useMemo(() => {
            const options: RouteOption[] = [
                {
                    text: 'deal_view.state.disqualified',
                    icon: <BrIcon iconName={IconName.BrCancelOutlineIcon} />,
                    path: AppRoute.disqualified,
                    legacy: false,
                    className: 'disqualified',
                },
            ];

            if (shouldShowSearches) {
                options.push({
                    text: 'navbar.configure_searches',
                    icon: <BrIcon iconName={IconName.BrSearchIcon} />,
                    legacy: false,
                    className: 'configure-searches',
                    path: `${AppRoute.configureNewSearch}${BuildSearchUrlQueryParam(
                        SearchType.Article,
                    )}` as AppRoute,
                    divider: true,
                });
            }

            return options;
        }, [shouldShowSearches]);

        return (
            <Tooltip
                side="right"
                triggerAsChild
                content={
                    <div className="flex flex-col">
                        {submenuOptions.map((option, index) => (
                            <Fragment key={index}>
                                {option.divider && <ItemRowDivider />}
                                <Link
                                    className={option.className}
                                    toLegacyApp={!!option.legacy}
                                    href={option.path}
                                >
                                    <ItemRow>
                                        {option.icon}
                                        <ItemRowText>
                                            {t(option.text)}
                                        </ItemRowText>
                                    </ItemRow>
                                </Link>
                            </Fragment>
                        ))}
                    </div>
                }
            >
                <div className="group cursor-pointer flex h-16 w-full items-center justify-center bg-navbar p-1">
                    <MoreHorizontal className="group-hover:text-indigo-600" />
                </div>
            </Tooltip>
        );
    },
);
