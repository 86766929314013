import { AbortParams } from 'src/data/api/api-client';
import {
    ContactDiscoveryResults,
    mapRawResponseToContactDiscoveryResults,
    DiscoveredContactProfileDetail,
} from '../domain/discovered-contact-profile.model';
import { ProcessGqlSdk } from 'src/data/api/graphql/graphql-client.wrapper';

interface FindContactsForCompanyParams extends AbortParams {
    companyName: string;
    keyword?: string;
    location?: string;
    title?: string;
    page: number;
}

interface GetLinkedinProfileContactInfoParams extends AbortParams {
    linkedinUserName: string;
}

export interface IContactFinderApi {
    findContactsForCompany: (
        params: FindContactsForCompanyParams,
    ) => Promise<ContactDiscoveryResults>;
    getLinkedinProfileContactInfo: (
        params: GetLinkedinProfileContactInfoParams,
    ) => Promise<DiscoveredContactProfileDetail>;
}

export const createContactFinderApi = (
    processGqlSdk: ProcessGqlSdk,
): IContactFinderApi => ({
    findContactsForCompany: async (
        params: FindContactsForCompanyParams,
    ): Promise<ContactDiscoveryResults> => {
        const { signal, ...apiParams } = params;

        const { getCompanyLinkedinProfiles } =
            await processGqlSdk.FindContactsForCompany(apiParams, {
                signal: signal,
            });

        return mapRawResponseToContactDiscoveryResults(
            getCompanyLinkedinProfiles.profiles,
            getCompanyLinkedinProfiles.metaData,
            params.companyName,
        );
    },

    getLinkedinProfileContactInfo: async (
        params: GetLinkedinProfileContactInfoParams,
    ): Promise<DiscoveredContactProfileDetail> => {
        const { signal, linkedinUserName } = params;

        const { getLinkedinProfileContactInformation } =
            await processGqlSdk.GetLinkedinProfileContactInfo(
                { linkedinUserName },
                { signal },
            );

        return getLinkedinProfileContactInformation;
    },
});
